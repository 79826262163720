.modules-training {
  display: block;
  width: 100%;

  .modules-wrapper {
    display: block;
    width: 100%;

    .modules-title {
      color: #54524e;
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: 0;
    }

    .modules-wrapper {
      padding-top: 1.75rem;
      padding-bottom: 1.75rem;

      .module-box {
        position: relative;
        display: flex;
        justify-content: space-between;
        border: 1px solid currentColor;
        border-right: 5px solid currentColor;
        margin-bottom: 1.75rem;

        .module-class {
          background-color: currentColor;

          font-size: 0.625rem;
          left: -1px;

          letter-spacing: 1.25px;
          margin: 0;
          padding: 0.5rem 1rem;
          position: absolute;
          text-transform: uppercase;
          top: 0;

          transform: translateY(-50%);

          span {
            text-transform: uppercase;
            color: #fff;
          }
        }

        .module-img {
          display: block;
          width: calc(100% / 3);
          min-height: 130px;
        }

        .module-infos {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          color: #54524e;
          padding-right: 1rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
          width: calc(100% / 3);

          .module-time {
            font-size: 0.75rem;
            letter-spacing: 1.25px;
            margin: 0;
            text-transform: uppercase;
          }

          .module-price {
            font-size: 1.25rem;
            font-weight: 500;
            margin: 0;
          }

          .module-link {
            display: flex;
            align-items: center;
            color: #003e69;
            font-size: 0.75rem;

            span {
              margin-right: 10px;
            }

            @media (min-width: 1025px) {
              &:hover {
                span {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .module-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          width: calc(100% / 3);
          color: #54524e;
          padding-left: 1rem;
          padding-top: 1rem;
          padding-bottom: 1rem;

          .release-date {
            font-weight: 500;
            font-size: 0.625rem;
            letter-spacing: 1.25px;
            margin: 0;
            text-transform: uppercase;
          }

          .module-heading {
            color: #54524e;
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 0;
            margin-top: 0;
          }

          .module-text {
            font-size: 0.825rem;
            letter-spacing: -0.56px;
          }
        }
      }
    }
  }
}
