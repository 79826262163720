.aside-menu-account {

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 365px) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .account-link-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    @media (max-width: 1023px) {
      padding: 0 10px;
      margin-left: 20px;
      margin-bottom: 0;

      &:first-child {
        margin-left: 0;
      }
    }

    @media (max-width: 767px) {
      padding: 0;
      margin-left: 0;
      margin-bottom: 20px;
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      display: block;
      width: 100%;
      max-width: 45px;

      &.arrow {
        max-width: 24px;
      }
    }

    .arrow {
      @media (max-width: 1023px) {
        display: none;
      }
    }

    span {
      font-family: $fontGotham;
      font-style: normal;
      font-weight: 350;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      color: $primary;
      margin-left: 10px;
      margin-right: 0px;
    }
  }
}

.account-overlay {
  display: none;
  position: fixed;
  background-color: rgba($black, 0.6);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;

  &.is-active {
    display: block;
  }

  .lds-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    position: relative;
    width: 120px;
    height: 120px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 120px;
    height: 120px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.account-settings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 68.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 65px;
  padding-top: 30px;
  position: relative;

  @media (max-width: 1023px) {
    justify-content: center;
  }

  .order-reminder-wrapper {
    margin-bottom: 20px;

    .order-reminder-header {
      display: flex;
      width: 100%;

      .order-reminder-header-icon {
        display: flex;
        width: 20%;
        max-width: 40px;
        margin-right: 10px;
      }

      .order-reminder-header-text {
        width: 80%;
      }
    }

    .button-link-steps{
      padding: 1px 50px;
      background-color: #ed7d31;
      border: 2px solid #000;
      border-radius: 7px;
      color: #fff;
      font-size: 16px;
      margin: 10px 0px;
      font-weight: 500;
      width: 80%;
    }
  }

  .order-wrapper {
    margin-bottom: 48px;
    padding: 10px;
    border: 1px solid rgba(#979797, 0.5);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .products-linked {
    width: 100%;
    max-width: 70%;

    @media (max-width: 1023px) {
      max-width: 100%;
    }

    .products-sortby {
      margin-bottom: 20px;

      .sortby {
        display: flex;
        align-items: center;

        .sortby-label {
          font-family: $fontGotham;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: $greyDark;
          margin-right: 10px;
        }

        .sortby-select-wrapper {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $greyDark;
          }
        }

        .sortby-select {
          background: #FFF;
          border: 1px solid #BCBBBA;
          padding: 10px 30px 10px 10px;
          font-family: $fontGotham;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: #224E85;
          appearance: none;
        }
      }
    }

    .no-order-placeholder {
      margin-top: 100px;
      text-align: center;
      font-weight: 800;
      color: $colorError;
    }

    .product-wrapper {
      display: flex;
      flex-direction: column;

      width: 100%;
      background: rgba(#979797, 0.1);
      border: 1px solid #979797;
      padding: 20px 30px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &.Product {
        background: rgba(84, 149, 66, 0.1);

        &.error {
          background: rgba(189, 79, 25, 0.1);
        }

        .product-employees {
          display: none;

          &.is-open {
            display: none;
          }
        }
      }

      &.Function {
        &.is-not-complete {
          background: rgba(189, 79, 25, 0.1);
        }

        &.is-complete {
          background: rgba(84, 149, 66, 0.1);

          .product-employees-top {
            display: none;
            .add-employees {
              display: none;
            }
          }

          .product-employees-all {
            .form-add-employee-product {
              display: none;
            }
          }

          .listing-employees-course {
            .product-employee-wrapper {
              button {
                display: none;
              }
            }
          }
        }
      }

      .top-infos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;

        .product-status {
          display: block;
          width: 100%;
          max-width: 40px;

          svg {
            display: block;
            width: 100%;
          }
        }

        .product-purshased-date {
          display: block;
          width: 100%;
          max-width: 90px;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 1.25px;
          text-transform: uppercase;

          color: #4A4845;
        }

        .product-transaction-id {
          display: block;
          width: 100%;
          max-width: 90px;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 1.25px;
          text-transform: uppercase;

          color: #4A4845;
        }

        .product-quantity {
          display: block;
          width: 100%;
          max-width: 90px;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 1.25px;
          text-transform: uppercase;

          color: #4A4845;
        }
      }

      .bottom-infos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;

        @media(max-width: 1023px) {
          flex-wrap: wrap;
        }

        .edit-product-employees,
        .link-to-vubiz {
          display: flex;
          align-items: center;

          svg {
            display: block;
            width: 100%;
            max-width: 20px;
          }
        }
        .product-title {
          display: block;
          width: 100%;
          max-width: 280px;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 17px;

          color: #54524E;

          @media (max-width: 1023px) {
            margin-bottom: 20px;
            margin-right: 20px;
          }
        }

        .product-event-date {
          display: block;
          width: 100%;
          max-width: 130px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 11px;
          letter-spacing: -0.56px;

          color: #696763;

          @media (max-width: 1023px) {
            margin-bottom: 20px;
            margin-right: 20px;
          }

          a {
            color: $primary;
            text-decoration: underline;
          }
        }

        .product-external-link {
          display: block;
          width: 100%;
          max-width: 165px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 11px;
          text-decoration-line: underline;

          color: #003E69;

          @media (max-width: 1023px) {
            margin-bottom: 20px;
            margin-right: 20px;
          }
        }
      }

      .product-employees {
        display: none;
        background-color: #fff;
        padding: 38px 16px;

        &.is-open {
          display: block;
        }

        .delete-employee-title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 15px;
          font-family: $fontGotham;

          /* Blue/XDark */

          color: #0A2F4D;
          margin-bottom: 16px;
          padding-bottom: 16px;
          border-bottom: 1px solid rgba(217, 232, 246, 0.5);
        }

        .product-employees-all {
          margin-top: 45px;

          .product-employee-wrapper {
            padding-bottom: 16px;
            border-bottom: 1px solid rgba(217, 232, 246, 0.5);
            margin-bottom: 16px;

            button {
              width: 100%;
              max-width: 41px;
            }

            .input-wrapper {
              width: 30px;
            }
            
            .employee-infos {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 15px;
            }
            
            .employee-firstname,
            .employee-lastname,
            .employee-mail,
            .employee-jobTitle,
            .employee-phone {
              width: calc((100% - 30px) / 6);
              word-break: break-word;
            }

            @media(max-width: 1023px) {
              flex-wrap: wrap;
              width: 100%;

              .input-wrapper {
                width: 100%;
                max-width: 100%;
              }

              .employee-firstname,
              .employee-lastname,
              .employee-mail,
              .employee-phone {
                width: 100%;
                max-width: 100%;
              }
            }

            button {
              background: none;
              border: none;
              cursor: pointer;

              svg {
                display: block;
                width: 100%;
              }
            }
          }

          .product-employee-buttons {
            margin-bottom: 48px;

            span {
              display: block;
              width: 100%;
              margin: 10px 0;
              line-height: 1.2;
              font-size: 12px;
              color: $primary;
              font-style: italic;

              &.is-red {
                color: $colorError;
              }
            }

            button {
              @media(max-width: 1023px) {
                margin-bottom: 20px;
              }

              &.is-off {
                pointer-events: none;
                opacity: 0.75;
              }
            }
          }
        }

        .product-employees-top {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .product-employees-title {
            display: block;
            width: 100%;
            max-width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.2;
            color: #0A2F4D;
          }

          .product-employees-text {
            display: block;
            width: 100%;
            max-width: 100%;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 1.2;
            color: #0A2F4D;
            margin-top: 10px;
          }

          .product-employees-add {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            border: none;
            width: 100%;

            padding-right: 0;

            svg {
              display: block;
              width: 100%;
              max-width: 16px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .product-employees-add {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;

    border: none;
    width: 100%;

    padding-right: 0;

    margin-bottom: 16px;

    svg {
      display: block;
      width: 100%;
      max-width: 16px;
      margin-right: 8px;
    }
  }

  .employees-table {
    width: 100%;
    max-width: 70%;
    margin-top: -50px;

    @media (max-width: 1023px) {
      max-width: 100%;
      margin-top: 0;
    }

    .add-employees {
      display: flex;
      max-width: 225px;
      margin: 0 auto;
      margin-right: 0;
      margin-bottom: 70px;

      &.is-off {
        pointer-events: none;
        opacity: 0.75;
      }


      svg {
        display: block;
        width: 100%;
        max-width: 16px;
        margin-right: 12px;
      }

      span {
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: #003E69;
      }
    }

    table {
      width: 100%;

      thead {
        th {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 11px;
          text-align: right;

          color: #696763;
          text-align: left;
          min-width: 45px;
          padding-bottom: 16px;
          border-bottom: 1px solid #BCBBBA;
        }
      }

      tbody {
        td {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 13px;
          text-align: left;

          /* Grey/Dark */

          color: #54524E;
          padding-top: 16px;
          padding-bottom: 16px;

          svg {
            max-width: 18px;
            cursor: pointer;
          }

          &:first-of-type {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            a {
              width: 50%;
              margin-right: 5px;
            }

            form {
              width: 50%;
            }

            button {
              padding: 0;
              border: none;
              outline: 0;
              background: transparent;
              cursor: pointer;
            }

            svg {
              width: 100%;
            }

            svg path {
              fill: #54524E;
            }
          }
        }
      }
    }
  }

  .login-form-wrapper {
    max-width: 70%;

    @media (max-width: 365px) {
      max-width: 100%;
    }

    .buttons-wrapper {
      display: flex;

      @media (max-width: 365px) {
        flex-wrap: wrap;
      }

      .button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &:first-child {
          margin-right: 10px;

          @media (max-width: 365px) {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.employees {
  .account-settings {
    padding-top: 100px;
  }
}

.employees-form,
.employees-form-edit,
.confirm-form-edit {
  display: none;

  &.is-open {
    display: block;
  }

  .overlay-employees,
  .overlay-employees-edit {
    position: fixed;
    inset: 0;
    height: 100%;
    width: 100%;
    background: rgba(#0A2F4D, 0.8);
    opacity: 0.8;
    z-index: 999;
  }

  .employees-form-wrapper,
  .confirm-form-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1000;
    padding: 100px 92px;
    width: 100%;
    height: 100vh;
    overflow: scroll;

    @media (max-width: 767px) {
      padding: 100px 30px;
    }

    .close-form,
    .close-form-edit {
      position: absolute;
      top: 20px;
      right: 18px;
      width: 100%;
      max-width: 40px;
      cursor: pointer;
    }

    .form,
    .login-form {
      width: 100%;
      max-width: 600px;
    }

    .employees-title {
      font-style: normal;
      font-weight: 500;
      font-size: 45px;
      line-height: 43px;
      /* identical to box height */

      letter-spacing: -0.3px;

      color: #144677;
      margin-bottom: 65px;
    }

    .buttons-wrapper {
      text-align: center;
    }
  
    .add-more-employee {
      margin: -20px auto 110px;
      border: none;
      background: transparent;
      cursor: pointer;
  
      &:hover {
        background: transparent;
      }
    }
  }
}

.employees {
  .employee-fields-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 1px;
      opacity: 0.3;
      background: #0A2F4D;
    }

    .employee-fields-title {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }

    .remove-less-employee {
      border: none;
      background: transparent;
      cursor: pointer;

      &:hover {
        background: transparent;
      }
    }

  }

  .employee-fields {
    margin-bottom: 80px;
  }
}

.need-help {
  display: none;
  width: 100%;
  font-style: italic;
  text-align: center;
  font-weight: normal;
  color: $primary;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;

  &.is-active {
    display: block;
  }

  a {
    text-decoration: underline;
  }
}
