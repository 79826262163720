.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #003e69;
  background-image: url(/img/footer-bg.svg);
  background-repeat: no-repeat;
  background-position: right bottom;

  color: #fff;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  @media (min-width: 768px) {
    padding-top: 3.75rem;
  }

  .footer-container {
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;

    .footer-logo-container {
      display: block;
      width: 100%;
      max-width: 250px;
      margin-bottom: 40px;

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }

      img {
        display: block;
        width: 100%;
        min-width: 255px;
        max-width: 255px;
      }
    }
  }

  .footer-copyright {
    display: block;
    width: 100%;
    font-size: 0.6875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 19px;
  }

  .container-wrapper {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .footer-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      max-width: 50%;

      @media (max-width: 767px) {
        margin-bottom: 2.5rem;
        max-width: 100%;
      }

      .content-top {
        display: flex;
        margin-bottom: 40px;
        width: 100%;

        @media (max-width: 767px) {
          justify-content: space-between;
        }

        form {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          max-width: 527px;
          row-gap: 1rem;

          label {
            display: block;
            font-size: 1.125rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 16px;
          }

          .hs-error-msg, .hs-error-msgs {
            font-size: 1rem;
          }

          .hs-submit input, .hs-submit button{
            position: relative;
            bottom: auto;
            right: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 170%;
            text-align: center;
            transition: all ease-out 0.2s;
            cursor: pointer;
            background-color: $blue;
            border: 0;
            color: $primary;
            font-family: inherit;
            font-size: 0.875rem;
            font-weight: 600;
            padding: 0.75rem 1.75rem;
            width: 100%;

            @media (min-width: 1025px) {
              &:hover {
                background-color: $blue;
                color: white;
              }
            }
          }

          .validation-wrapper {
            margin-top: 15px;
            font-size: 1.125rem;
            font-weight: 500;
          }

          .input-wrapper {
            position: relative;
          }

          input.input, input.hs-input {
            display: flex;
            position: relative;
            height: 47px;
            min-height: 47px;
            width: 100%;
            border-radius: 0;
            background-color: #fff;
            border: 0;
            color: #003e69;
            font-size: 0.875rem;
            padding: 0.625rem 1rem;
          }
        }

        .footer-col {
          width: 100%;
          //max-width: 113px;
          max-width: calc((100% / 2) - (67px / 2));
          margin-right: 67px;

          &:last-child {
            margin-right: 0;
          }

          .footer-link {
            display: block;
            font-size: 0.75rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 33px;

            &:last-child {
              margin-bottom: 0;
            }

            span {
              @media (min-width: 1025px) {
                &:hover {
                  border-bottom: 1px solid #fff;
                }
              }

            }
          }
        }
      }

      .content-bottom {
        .tel-link {
          font-size: 1.125rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
        }

        .socials-heading {
          display: block;
          font-size: 1.125rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 16px;

        }
        .socials-links-wrapper {
          display: flex;

          .social-link {
            margin-right: 16px;

            img {
              display: block;
              width: 100%;
              max-width: 30px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &.left {
        margin-right: 184px;

        @media (max-width: 1024px) {
          margin-right: 92px;
        }

        @media (max-width: 767px) {
          margin-right: 0;
        }
      }

      &.right {
        .content-top {
          margin-bottom: 40px;
        }
      }
    }
  }
}
