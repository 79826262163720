.module-entry {
  display: block;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  width: 60%;

  @media (max-width: 767px) {
    width: 100%;
  }

  .media-button {
    margin-top: 30px;
  }

  .event-detail-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: solid 1px #bcbbba;
    background-color: #ffffff;
    padding: 29px 26px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }

  }

  .module-title {
    color: #4a4845;
    display: inline-block;
    font-size: 2.875rem;

    letter-spacing: -0.16px;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0.325rem;
    position: relative;
    margin-bottom: 30px;

    @media(max-width: 1024px) {
      font-size: 2rem;
    }

    @media(max-width: 767px) {
      font-size: 1.875rem;
    }

    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      position: absolute;
      bottom: -0.375rem;
      left: 0;
    }
  }

  .module-duration {
    font-size: 1.125rem;
    font-weight: 500;
    color: #5e5c58;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
  }

  .module-intro {
    font-size: 0.825rem;
    letter-spacing: -0.56px;
    line-height: 1.75;
    margin-top: 1.875rem;

    h1 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.16px;
      font-size: 2.75rem;
      font-weight: bold;
      color: #003e69;


      &:after {
        content: '';
        display: block;
        width: 2.5rem;
        height: 0.25rem;
        background-color: #5cb7e7;
        margin-top: 8px;
      }
    }

    h2 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.16px;
      font-size: 2rem;
      font-weight: bold;
      color: #003e69;

    }

    h3 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.16px;
      font-size: 2rem;
      font-weight: bold;
      color: #323232;
    }

    h4 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.16px;
      font-size: 1.75rem;
      font-weight: bold;
      color: #003e69;
    }

    h5 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.16px;
      font-size: 1.75rem;
      font-weight: bold;
      color: #323232;
    }

    h6 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.16px;
      font-size: 1.50rem;
      font-weight: bold;
      color: #323232;
    }

    p {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }

    a {
      color: $primary;
      text-decoration: underline;
    }
  }

  .module-img {
    display: block;
    position: relative;
    width: 100%;
    min-height: 300px;
    padding-top: 1rem;
    margin-top: 1.875rem;
    background-size: cover;

    .module-class {
      font-size: 0.625rem;
      left: 0;

      letter-spacing: 1.25px;
      margin: 0;
      padding: 0.5rem 1rem;
      position: absolute;
      text-transform: uppercase;
      top: 0;

      transform: translateY(-50%);

      span {
        text-transform: uppercase;
        color: #fff;
      }
    }
  }

  .module-price {
    font-size: 1.125rem;
    font-weight: 500;
    color: #5e5c58;
    margin-top: 1.875rem;
    margin-bottom: 0.75rem;
  }
}

.module-entry-related {
  display: block;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  width: 30%;
  margin-left: 10%;

  @media (max-width: 767px) {
    width: 100%;
  }

  .related-event-wrapper {
    display: flex;
    flex-direction: column;

    .related-event {
      display: block;
      width: 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
      margin-bottom: 20px;
      padding: 15px 18px;

      .related-event-title {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.84px;
        color: #54524e;
        margin-bottom: 8px;
      }

      .related-event-text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.56px;
        color: #54524e;
        margin-bottom: 26px;
      }

      .related-event-link {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        text-align: right;

        color: #003e69;
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 700;
        padding: 0;
        background-color: transparent;
        border: 0;

        svg {
          margin-left: 10px;
        }

        @media (min-width: 1025px) {
          &:hover {
            span {
              border-bottom: 1px solid #003e69;
            }
          }
        }


      }

    }
  }

  .related-title {
    color: #4a4845;
    display: inline-block;
    font-size: 1.5rem;

    letter-spacing: -0.16px;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0.325rem;
    position: relative;
    font-weight: 400;
    padding: 0;
    margin-bottom: 20px;
  }

  .topics-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px) {
      justify-content: flex-start;
    }
    .topic-link {
      background-color: #fff;
      border: 1px solid #5cb7e7;
      border-radius: 1.25rem;
      color: #003e69;
      display: block;
      font-size: 0.75rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
      margin-top: 0.625rem;
      margin-left: 0.325rem;

      text-align: center;

      @media (min-width: 1025px) {
        &:hover {
          background-color: #5cb7e7;
          font-weight: 500;
        }
      }


    }
  }


}


.homepage {
  .module-entry-related {
    width: 100%;
    margin-left: 0;



    .related-title {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;

      @media (max-width: 767px) {
        text-align: left;
      }
    }

    .topic-link {
      display: inline-block;
      max-width: 100%;
    }
  }
}
