// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontNexa: Nexa, Helvetica Neue, Helvetica, Arial, sans-serif;
$fontGotham: 'Gotham SSm', Helvetica Neue, Helvetica, Arial, sans-serif;

// Media Queries
$l: 1440;
$m: 1024;
$s: 768;
$xs: 425;
$xxs: 375;

// Accents Colors
$colorAccent1: #00BCE7;
$colorAccent2: #004053;
$colorAccent3: #FFCB07;
$colorAccent4: #ffd100;

$lightText: #73879A;

// Texts
$dark: #0D313F;
$grey: #F4F8FF;
$light: #FFFFFF;

$colorSuccess: #00966D;
$colorError: #FF564A;

$black: #000;
$greyDarker: #e4e4e3;
$greyDark: #5e5c58;
$grey: #4a4845;
$greyLight: #54524e;
$greyLighter: #b8b8b8;
$white: #fff;

$primary: #003e69;
$blue: #5cb7e7;
$blue2: #addbf3;
$blueLight: #0065c3;
$blueLighter: #cce0f3;
$blueLightest: #d9e8f6;

$orangeBrown: #bd4f19;
$purple: #6b1f7c;
$green: #539535;
$yellow: #ffea8e;
$teal: #00806F;

//Tags
$darkOrange: #5E270C;
