// --------------------------------------------------
// Import Fonts
// --------------------------------------------------


@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-ExtraLight.woff2') format('woff2'),
  url('/fonts/GothamSSm-ExtraLight.woff') format('woff'),
  url('/fonts/GothamSSm-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-BoldItalic.woff2') format('woff2'),
  url('/fonts/GothamSSm-BoldItalic.woff') format('woff'),
  url('/fonts/GothamSSm-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-BookItalic.woff2') format('woff2'),
  url('/fonts/GothamSSm-BookItalic.woff') format('woff'),
  url('/fonts/GothamSSm-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-Bold.woff2') format('woff2'),
  url('/fonts/GothamSSm-Bold.woff') format('woff'),
  url('/fonts/GothamSSm-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-LightItalic.woff2') format('woff2'),
  url('/fonts/GothamSSm-LightItalic.woff') format('woff'),
  url('/fonts/GothamSSm-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-Black.woff2') format('woff2'),
  url('/fonts/GothamSSm-Black.woff') format('woff'),
  url('/fonts/GothamSSm-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-MediumItalic.woff2') format('woff2'),
  url('/fonts/GothamSSm-MediumItalic.woff') format('woff'),
  url('/fonts/GothamSSm-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-Medium.woff2') format('woff2'),
  url('/fonts/GothamSSm-Medium.woff') format('woff'),
  url('/fonts/GothamSSm-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-Book.woff2') format('woff2'),
  url('/fonts/GothamSSm-Book.woff') format('woff'),
  url('/fonts/GothamSSm-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-ExtraLightItalic.woff2') format('woff2'),
  url('/fonts/GothamSSm-ExtraLightItalic.woff') format('woff'),
  url('/fonts/GothamSSm-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-Light.woff2') format('woff2'),
  url('/fonts/GothamSSm-Light.woff') format('woff'),
  url('/fonts/GothamSSm-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham SSm';
  src: url('/fonts/GothamSSm-BlackItalic.woff2') format('woff2'),
  url('/fonts/GothamSSm-BlackItalic.woff') format('woff'),
  url('/fonts/GothamSSm-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
