@mixin ie-only() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

.alert-navigation {
  background-color: #fff;
  width: 100%;

  .alert-wrapper {
    display: flex;
    align-items: stretch;
    width: 100%;

    @media(max-width: 1024px) {
      flex-direction: column;
    }

    .alert-heading {
      width: 100%;
      background-color: $colorAccent4;
      padding: 8px 11px;
      max-width: 215px;

      font-family: $fontGotham;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #003e69;

      @media (max-width: 1024px) {
        //font-size: 10px;
        max-width: 100%;
        text-align: left;
      }

      @media (max-width: 767px) {
        //font-size: 8px;
      }
    }

    .alert-content-wrapper {
      width: 100%;
      .alert-content {
        width: 100%;
        background-color: rgba(255, 209, 0, 0.25);
        padding: 8px 11px;
        font-family: $fontGotham;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #003e69;

        @media (max-width: 1024px) {
          //font-size: 10px;
        }

        @media (max-width: 767px) {
          //font-size: 8px;
        }

        b,
        strong {
          font-weight: bold;
        }
        a {
          text-decoration: underline;
        }

      }
    }


  }
}


.header-container {
  position: relative;

  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }

  .card-entry {
    display: block;
    width: calc((100% / 4) - (45px / 4));
    background-color: #fff;
    margin-right: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 100px;
    right: 100px;


    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1024px) {
      display: none;
      width: calc((100% / 2) - (30px / 4));
      margin-bottom: 15px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 0;
      min-width: 175px;

      &:nth-child(2n) {
        margin-right: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }

    .entry-embed-html {
      display: block;
      width: 100%;
      height: 100%;

      figure {
        margin: 0;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .entry-thumb {
      display: block;
      width: 100%;
      height: 128px;
      background-color: #333;

      @media (max-width: 767px) {
        height: 88px;
      }
    }

    .entry-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 0.75rem;
      padding-top: 2rem;
      position: relative;

      .entry-class {
        padding: 9px 12px 9px 15px;
        background-color: #bd4f19;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 1.25px;
        color: #ffffff;
        position: absolute;
        left: 0;
        top: -15px;

        @media (max-width: 767px) {
          font-size: 8px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: 1px;
        }
      }

      .entry-heading {
        font-size: 1.125rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.16px;
        margin-bottom: 0;
        margin-top: 0;
        margin-bottom: 8px;
        color: #54524e;

        @media (max-width: 767px) {
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.56px;
        }
      }

      .entry-rich-text {
        font-size: 0.825rem;
        letter-spacing: -0.56px;
        line-height: 1.75;
        color: #54524e;
        margin-bottom: 26px;

        @media (max-width: 767px) {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.56px;
          margin-bottom: 10px;
          margin-top: 3px;
        }
      }

      .entry-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        text-align: right;

        color: #003e69;
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 700;
        padding: 0;
        background-color: transparent;
        border: 0;

        @media (max-width: 767px) {
          justify-content: flex-start;
          margin-top: 5px;

          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.08px;
        }

        svg {
          margin-left: 10px;
        }

      }
    }
  }


  .main-header-wrapper {
    position: relative;
    background: $white;
    z-index: 2;
  }

  .header {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 1rem;
    position: relative;

    @media (max-width: 1023px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .navigations-container {
      width: 100%;

      @media (max-width: 1023px) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        max-width: 40px;
      }

      .mobile-menu-btn {
        display: none;

        @media (max-width: 1023px) {
          display: block;
          width: 100%;
          min-width: 36px;
          max-width: 36px;
          max-height: 36px;
        }

        @media (max-width: 340px) {
          padding-right: 10px;
        }
      }



      .top-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;

        max-width: 68.75rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1rem;
        padding-right: 1rem;

        &.alert {
          padding-top: 16px;
        }


        @media (max-width: 1023px) {
          justify-content: flex-end;
          display: none;
          max-width: 100%;
          margin-left: inherit;
          margin-right: inherit;
          padding-left: 16px;
          padding-right: 16px;
        }

        .top-links {
          display: flex;
          flex-wrap: nowrap;
        }

        .top-ctas {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          @media (max-width: 1023px) {
            position: absolute;
            top: 0;
            right: 10px;
            flex-direction: row-reverse;
          }

          .top-nav-link {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 13px;
            color: #fff;

            @media (max-width: 1023px) {
              display: none;
            }
          }
        }

        .separator {
          display: block;
          width: 2px;
          height: 3.375rem;
          background-color: #5cb7e7;
          margin-right: 15px;
          margin-left: 15px;

          @media (max-width: 1023px) {
            margin-right: 5px;
            margin-left: 5px;
          }

        }
      }

      .bottom-navigation {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        .top-nav-link.is-outlined.is-dark {
          display: none;

          @media (max-width: 1023px) {
            display: block;
          }

          @media (max-width: 599px) {
            display: none;
          }
        }

        .bottom-nav-link {
          display: flex;
          margin-right: 37px;
          font-size: 1rem;
          line-height: 1;
          position: relative;

          font-size: 15px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #003e69;

          @include ie-only(){
            margin-right: 22px;
          }

          @media (max-width: 1023px) {
            display: none;
          }

          @media (max-width: 1110px) {
            margin-right: 20px;
          }

          &.with-icon {
            display: flex;
            align-items: flex-end;

            @media (max-width: 1023px) {
              display: none;
            }

            svg {
              width: 20px;
              margin-left: 0;
            }

            @media (min-width: 1025px) {
              &:hover {
                &::after {
                  display: none;
                }
              }
            }
          }

          @media (min-width: 1025px) {
            &:hover {
              &::after {
                content: '';
                height: 5px;
                width: 100%;
                position: absolute;
                bottom: -22px;
                background-color: $blue;
              }
            }
          }



          &.has-border-bottom {
            &::after {
              content: '';
              height: 5px;
              width: 100%;
              position: absolute;
              bottom: -22px;
              @include ie-only(){
                bottom: -55px;
                left: 0px;
              }
              background-color: $blue;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          svg {
            margin-left: 5px;
            @include ie-only(){
              margin-left: -5px;
            }

            &.is-hidden {
              display: none;
            }
          }
        }


      }
    }

    &.blue-background {
      background-color: #003E69;
      max-width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 0;
      padding-right: 0;
    }

    &.alert {
      padding-top: 0;

      @media(max-width: 1024px) {
        padding-bottom: 0;
      }

      .navigations-container {
        width: 100%;
        max-width: 100%;
      }
    }

    .logo-wrapper {
      width: 100%;
      max-width: 250px;
      //min-width: 250px;
      margin-right: 20px;

      @media (max-width: 340px) {
        width: calc(100% - 40px);
        margin-right: 10px;
        max-width: 100%;
        //min-width: 200px;
      }

      img {
        display: block;
        width: 100%;
        min-width: 100%;
      }
    }

    .top-nav-link {
      margin: 0px 26px 0px 0px;

      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 11px;
      color: #FFFFFF;

      @media (max-width: 767px) {
        margin-right: 16px;
      }

      &:last-child {
        margin-right: 0;
      }

      @media (min-width: 1025px) {
        &:hover {
          text-decoration: underline;
        }
      }




      &.current-user {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @media (max-width: 1023px) {
          color: #003E69;
          background: rgba(217, 232, 246, 0.5);
        }

        svg {
          display: block;
          width: 100%;
          max-width: 40px;
        }

        span {
          margin-left: 6px;
          margin-right: 6px;
        }

        .arrow {
          transform: rotate(0);
          transition: transform .15s ease-in-out;
        }

        &.is-open {
          .arrow {
            transform: rotate(-180deg);
          }
        }
      }

      &.is-outlined {
        border: 1px solid #fff;
        padding: 10px 16px;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 13px;
        text-align: center;

        @media (min-width: 1025px) {
          &:hover {
            color: #003e69 !important;
            background-color: #fff;
          }
        }


      }

      &.is-dark {
        border: 1px solid #5CB7E7;
        background-color: #5CB7E7;
        color: #003E69 !important;
        transition: all .35s ease-in-out;

        @media (min-width: 1025px) {
          &:hover {
            color: #003E69;
            border: 1px solid #003E69;
            background-color: #fff;
          }
        }

      }

      &.has-icon {
        display: flex;
        align-items: center;
        margin-right: 0;

        @media (max-width: 1023px) {
          display: flex;
          margin-right: 0;

          span {
            display: none;
          }
        }

        span {
          margin-left: 5px;
        }

        @media (min-width: 1025px) {
          &:hover {
            border-bottom: inherit;

            span {
              border-bottom: 1px solid #3f4946;
            }
          }
        }


      }
    }


  }

  .sub-nav {
    display: none;
    background-color: #fff;
    position: relative;
    width: 100%;

    &.is-active {
      display: block;
      position: absolute;
      //top: 160px;
      left: 0;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      z-index: 9999;
    }

    &:after {
      position: absolute;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      height: 0.25rem;
      background-color: #5cb7e7;
    }

    .sub-nav-container {
      position: relative;
      padding-bottom: 6rem;
      padding-top: 3rem;
      border-top: 1px solid #5cb7e7;
      max-width: 68.75rem;
      margin-left: auto;
      margin-right: auto;
      padding-right: 1rem;

      .close-sub-nav {
        position: absolute;
        display: block;
        width: 100%;
        max-width: 32px;
        top: 20px;
        right: 0;
        cursor: pointer;
      }
    }

    @media (max-width: 1130px) {
      padding-left: 1rem;
    }

    .bottom-sub-navigation {
      display: none;
      min-height: 378px;

      &.is-active {
        display: flex;
        flex-direction: column;
      }

      .bottom-sub-nav-link {
        font-size: 1rem;
        color: #003e69;
        font-weight: 500;
        max-width: calc(100% - 300px);
        margin-bottom: 12px;

        span  {
          @media (min-width: 1025px) {
            &:hover {
              border-bottom: 1px solid $primary;
            }
          }

        }

        svg {
          margin-left: 15px;
        }

        &.main-link {
          width: 100%;
          max-width: 300px;

          font-size: 1.5rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.24px;
          color: #54524e;

          &:after {
            content: '';
            display: block;
            width: 2.5rem;
            height: 0.25rem;
            background-color: #5cb7e7;
            margin-top: 8px;
            margin-bottom: 31px;
          }
        }
      }
    }
  }

  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    right: -100%;
    width: 90vw;
    height: 100vh;
    background-color: white;
    z-index: 999;
    overflow: scroll;
    transition: all 0.15s ease-in-out;
    opacity: 0;

    @media (max-width: 1023px) {

      &.is-active {
        right: 0;
        opacity: 1;
        z-index: 9999;
      }
    }

    @media (max-width: 520px) {
      width: 100vw;
    }

    .mobile-menu-header {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 1.25rem 2.25rem;

      @media (max-width: 365px) {
        padding: 1.25rem 0.25rem;
      }

      &.account-is-active {
        padding-bottom: 220px;

      }

      .wrapper {
        display: flex;
      }

      .top-nav-mobile {
        display: flex;
        align-items: center;
        margin-top: 2.25em;

        a {
          margin-right: 2.25em;

          &:last-child {
            margin-right: 0;
          }

          @media (max-width: 365px) {
            margin-right: 1.25em;
          }
        }
      }

      .close-menu {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        max-width: 32px;
      }

      .top-nav-link {
        margin: 0px 26px 0px 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 11px;
        color: #FFFFFF;

        &.is-shop {
          max-height: 40px;
        }

        @media (max-width: 767px) {
          margin-right: 16px;
        }

        @media (max-width: 339px) {
          margin-right: 1px;
        }

        &:last-child {
          margin-right: 0;
        }

        @media (min-width: 1025px) {
          &:hover {
            text-decoration: underline;
          }
        }



        &.current-user {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          @media (max-width: 1023px) {
            color: #003E69;
            background: rgba(217, 232, 246, 0.5);
          }

          svg {
            display: block;
            width: 100%;
            max-width: 40px;
          }

          span {
            display: block;
            margin-left: 6px;
            margin-right: 6px;

            @media (max-width: 365px) {
              max-width: 156px;
              width: 100%;
              overflow: hidden;
            }

            @media (max-width: 280px) {
              max-width: 56px;
              width: 100%;
              overflow: hidden;
            }
          }

          .arrow {
            transform: rotate(0);
            transition: transform .15s ease-in-out;
          }

          &.is-open {
            padding-bottom: 11px;

            .arrow {
              transform: rotate(-180deg);
            }
          }
        }

        &.is-outlined {
          border: 1px solid #0A2F4D;
          padding: 10px 16px;

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 13px;
          text-align: center;

          color: #0A2F4D;

        }

        &.is-dark {
          border: 1px solid #5CB7E7;
          background-color: #5CB7E7;
          color: #003E69;

        }

        &.has-icon {
          display: flex;
          align-items: center;
          margin-right: 0;

          @media (max-width: 1023px) {
            display: flex;
            margin-right: 0;

            span {
              display: none;
            }
          }

          span {
            margin-left: 5px;
          }

          @media (min-width: 1025px) {
            &:hover {
              border-bottom: inherit;

              span {
                border-bottom: 1px solid #3f4946;
              }
            }
          }


        }
      }
    }

    .mobile-menu-footer {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 90vw;
      background-color: #fff;
      border-top: 1px solid #4a4845;

      @media (max-width: 520px){
        width: 100vw;
      }


      input {
        display: block;
        width: 100%;
        border: 0;

      }

      svg {
        position: absolute;
        top: 50%;
        right: 1.25rem;
        transform: translateY(-50%);

        @media (max-width: 280px) {
          right: 2rem;
        }
      }
    }

    .links-wrapper {
      display: flex;
      flex-direction: column;
      padding: 1.25rem 2.25rem;

      @media (max-width: 365px) {
        padding: 1.25rem 0.25rem;
      }

      .links-content {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        .link-parent {
          font-size: 0.9rem;
          color: #003e69;
          font-weight: 500;
          margin-bottom: 0.875rem;

          @media (max-width: 1023px) {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 110%;
            color: #0A2F4D;

            &.is-open {
              svg {
                transform: rotate(90deg);
              }
            }
          }

          span  {

            @media (min-width: 1025px) {
              &:hover {
                border-bottom: 1px solid $primary;
              }
            }

          }

          svg {
            margin-left: 15px;
          }

          &:after {
            content: '';
            display: block;
            width: 2.5rem;
            height: 0.25rem;
            background-color: #5cb7e7;
            margin-top: 8px;
          }
        }

        .links-children {
          display: none;
          flex-direction: column;

          &.is-active {
            display: flex;
          }

          .link-children {
            font-size: 0.8rem;
            color: #003e69;
            font-weight: 500;
            margin-bottom: 0.75rem;

            @media (max-width: 1023px) {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 110%;
              color: #003E69;
              margin-bottom: 16px;

              svg {
                display: none;
              }
            }

            span  {

              @media (min-width: 1025px) {
                &:hover {
                  border-bottom: 1px solid $primary;
                }
              }

            }

            svg {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }

  .overlay-mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100vw;
    height: 100vh;
    background-color: rgba($black, 0.45);
    z-index: 998;
    opacity: 0;
    transition: all 0.001s ease-in-out;

    &.is-active {
      right: 0;
      opacity: 1;
    }
  }

  .user-actions {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    position: absolute;
    top: 56px;
    left: 0;
    z-index: 9999999;
    padding: 32px;
    box-shadow: 0px 6px 12px rgba(0, 62, 105, 0.24);

    width: 100%;
    max-width: 208px;

    transition: top .35s ease-in-out;

    @media (max-width: 1023px) {
      background: rgba(217, 232, 246, 1);
      left: 2.25rem;
      max-width: calc(100% - 5rem);
      top: 60px;
      box-shadow: inherit;
    }

    @media (max-width: 280px) {
      left: 0.25rem;
    }

    &.is-open {
      display: flex;
    }

    &.is-mobile {

      margin-top: 11px;
      z-index: 9999999;
    }

    .user-action-link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      color: #0A2F4D;
      margin-bottom: 16px;

      @media (max-width: 1023px) {
        color: #003E69;
      }


      &:first-child {
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(217, 232, 246, 0.5);
      }

      &:last-child {
        padding-top: 16px;
        border-top: 1px solid rgba(217, 232, 246, 0.5);
        margin-bottom: 0;
      }

      svg {
        display: block;
        width: 100%;
        max-width: 24px;
      }
    }
  }
}

.overlay-desktop-transparent {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($primary, 0);
  z-index: 9999;

  &.is-active {
    display: block;
  }
}
