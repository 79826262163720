.login-form-wrapper {
  width: 100%;
  max-width: 80%;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  .alert-success {
    width: 100%;
    max-width: 345px;
    margin: 0 auto;
    background-color: rgba($colorSuccess, .3);
    padding: 15px 20px;
    margin-bottom: 50px;
  }

  .alert-activated-account {
    width: 100%;
    max-width: 345px;
    margin: 0 auto 50px;
    padding: 15px 20px;
    text-align: center;
    color: $white;
    background: $primary;
  }
}

.login-to-registrar {
  width: 100%;
  max-width: 20%;

  @media (max-width: 767px) {
    max-width: 100%;
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
  }

  .register-button {
    @media (max-width: 767px) {
      display: block;
      margin: 0 auto;
      text-align: center;
      max-width: 175px;
    }
  }

  .registrar-title {
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    margin-bottom: 15px;
    color: $primary;

    @media (max-width: 767px) {
      text-align: center;
    }
  }
}

.form,
.login-form,
.reset-form,
.auth0-login-form,
.reset-form-password {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 345px;
  margin-left: auto;
  margin-right: auto;

  .toggle-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    .toggle-label {
      width: 100%;
      max-width: calc(100% / 2 - 31px);
      position: relative;

      &.left {
        input {
          opacity: 0.001;
        }

        label {
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translate(0, -50%);
          text-align: right;
        }
      }

      &.right {
        input {
          opacity: 0.001;
        }

        label {
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translate(0, -50%);
        }
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 96px;
      height: 34px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $white;
      border: 1px solid $primary;
      -webkit-transition: .4s;
      transition: .4s;

      &:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 3px;
        background-color: $primary;
        -webkit-transition: .4s;
        transition: .4s;
      }
    }

    input:checked + .slider {
      background-color: $white;
      border: 1px solid $primary;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $dark;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 30px;

    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;

      .tooltip-text {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;

        left: 20px;
        top: 50%;
        transform: translate(0, -50%);

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 100%;
          margin-top: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent black transparent transparent;
        }
      }

      &:hover {
        .tooltip-text {
          visibility: visible;
        }
      }
    }

    ul.errors {
      padding: 0;
      margin-bottom: 0;
      margin-top: 5px;
      li {
        display: block;
        color: $colorError;
        font-size: 12px;
      }
    }

    &.is-radio {
      position: relative;
      width: calc(100% / 2);

      &:first-child {
        label {
          left: 0;
        }
      }
      &:last-child {

        label {
          right: 0;
        }
      }

      input {
        opacity: 0.10;
      }

      label {
        width: 100%;
        position: absolute;
        top: -1px;
        max-width: 130px;
      }
    }

    .toggle-password,
    .toggle-confirm-password,
    .toggle-new-password {
      cursor: pointer;
      width: 100%;
      max-width: 16px;
      position: absolute;
      right: 15px;
      top: 42px;

      .toggle-password-open,
      .toggle-password-close {
        width: 100%;
        display: none;

        &.is-active {
          display: block;
        }
      }


    }

    &:last-of-type {
      margin-bottom: 15px;
    }

    label {
      display: block;
      width: 100%;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.47px;
      margin-bottom: 10px;
    }

    ul.city-helper-text {
      opacity: 0;
      position: absolute;
      top: -100px;
      left: 0;
      display: block;
      margin-top: 6px;
      font-family: $fontGotham;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #bcbbba;
      display: none;

      &.is-active {
        position: relative;
        opacity: 1;
        top: 0;
        display: block;
      }

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &.valid {
          margin-bottom: 8px;
          color: #558f26;
        }

        &.no-valid {
          color: red;
        }
      }
    }
    ul.helper-text {
      opacity: 0;
      position: absolute;
      top: -100px;
      left: 0;
      display: block;
      margin-top: 6px;
      font-family: $fontGotham;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #BCBBBA;
      display: none;

      &.is-active {
        position: relative;
        opacity: 1;
        top: 0;
        display: block;
      }

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &.valid {
          margin-bottom: 8px;
          color: #558F26;
        }

        &.no-valid {
          color: red;
        }
      }
    }

    .email-format,
    .phone-format {
      display: none;

      &.is-active {
        display: block;
        color: $colorError;
        font-size: 12px;
      }
    }

    .error-message {
      display: none;

      &.is-active {
        display: block;
        color: $colorError;
        font-size: 12px;
      }
    }

    .required-message {
      display: none;

      &.is-required {
        display: block;
        color: $colorError;
        font-size: 12px;
      }
    }

    .not-same-message,
    .city-criteria-not-met,
    .password-criteria-not-met,
    .not-same-email {
      display: none;

      &.is-not-same {
        display: block;
        color: $colorError;
        font-size: 12px;
      }
    }

    input, select {
      border: none;
      background-color: rgba(#D9E8F6, 50%);
      padding: 15px 10px;
      color: $primary;

      &.is-not-same {
        border: 1px solid $colorError;
        position: relative;

        &:focus {
          border: 2px solid $colorError !important;
          position: relative;
        }
      }

      &.is-not-same:focus {
        border: 2px solid $colorError;
        position: relative;
      }

      &.is-empty {
        border: 1px solid $colorError;
        position: relative;
      }

      &::placeholder {
        color: $primary;
      }

      &[type=password] {
        &.red-flag {
          border: 1px solid red;
        }
      }
    }

    select {
      border: 1px solid #BCBBBA;
      background-color: #fff;
      padding: 15px 10px;
      color: $primary;

      &::placeholder {
        color: $primary;
      }
    }

    .fake-select {
      position: relative;

      svg {
        display: block;
        position: absolute;

        width: 9px;
        top: 50%;
        right: 10px;

        transform: rotate(-180deg);

        &.is-open {
          transform: rotate(0deg);
        }
      }
    }

    .fake-select-input {
      display: block;
      width: 100%;
      border: 1px solid #BCBBBA;
      background-color: #fff;
    }

    .fake-options {
      display: none;
      position: relative;
      border: 1px solid #BCBBBA;
      border-top: none;
      max-height: 255px;
      overflow: scroll;

      &.is-open {
        display: block;
      }
    }

    .fake-options-fade {
      //display: block;
      //position: absolute;
      //width: 100%;
      //height: 15%;
      //bottom: 0;
      //background: linear-gradient(rgba(#fff, 0.5), #fff);
    }

    .fake-option-content {
      width: 100%;
      .fake-option-wrapper {
        margin-bottom: 20px;

        .fake-margin {
          height: 30px;
        }

        .fake-option-title {
          text-align: left;
          padding-right: 20px;
          padding-left: 5px;
          color: #144677;
        }

        .fake-option-value {
          padding: 0 5px;
          padding-right: 20px;
          cursor: default;
          color: #144677;

          @media (min-width: 1025px) {
            &:hover {
              background-color: #D9E8F6;
            }
          }


        }
      }
    }
  }

  .login-submit {
    align-self: center;
  }

  .forgot-password {
    align-self: flex-end;
    margin-bottom: 30px;
    text-decoration: underline;
  }
}

.auth0-login-form {
  .forgot-password {
    margin-top: 20px;
  }
}

.error-message {
  display: block;
  color: $colorError;
  font-size: 16px;
}

.warning-message {
  padding: 10px 15px 10px 20px;
  margin-bottom: 30px;
  border-radius: 3px 0 0 3px;
  border-left: 3px solid $colorError;
  background: rgba($colorError, .1);
  color: $colorError;
  font-size: 14px;
}
