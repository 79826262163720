.navigation-child-links {
  margin-right: 5rem;

  .child-title {
    display: block;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.16px;
    color: #696763;
    margin-bottom: 37px;

    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      margin-top: 8px;
    }
  }

  .child-list {
    display: flex;
    flex-direction: column;

    .child-link {
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-width: 250px;
      max-width: 250px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #004777;
      padding-top: 12px;
      padding-bottom: 12px;

      border-bottom: 1px solid #e4e4e3;

    }
  }
}
