.professionals-breadcrumb {
  width: 100%;
  background: $primary;
  display: flex;
  align-items: center;
  position: relative;

  p {
    width: 100%;
    max-width: 68.75rem;
    margin: 0 auto;
    padding: 14px 1rem;
    font-family: $fontGotham;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
  }

  a {
    text-decoration: underline;
  }
}

.professionals-container {
  display: flex;
  width: 100%;
  max-width: 68.75rem;
  margin: 50px auto 140px;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .professionals-col {
    &.content {
      width: calc((100% / 3) * 2);

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &.sidepanel {
      width: calc(100% / 3);

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .professionals-img {
    display: block;
    width: 100%;
  }

  .professionals-name-and-socials-container{
    display: flex;
  }

  .professionals-socials {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 17px;

    a {
      position: relative;
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-right: 15px;
      border-radius: 50%;
      background: $colorAccent2;
      transition: .3s all ease;

      &:last-of-type {
        margin-right: 0;
      }

      @media (min-width: 1025px) {
        &:hover {
          background: $colorAccent1;
        }
      }



      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        transform: translate(-50%, -50%);

        path {
          fill: $white;
        }
      }
    }
  }

  .professionals-name {
    margin: 0 0 5px;
    font-family: $fontGotham;
    font-size: 45px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: $colorAccent2;
  }

  .professionals-certification {
    margin: 0 0 25px;
    font-family: $fontGotham;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: #75726d;
  }
}

.professionals-blocks {

  .block {
    margin-bottom: 50px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .introduction {
    .introduction-heading {
      position: relative;
      margin: 0 0 60px;
      font-family: $fontGotham;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: -0.84px;
      color: #8b8983;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 75px;
        height: 5px;
        background: $colorAccent1;
      }
    }

    .introduction-text {
      b,
      strong {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.22;
        letter-spacing: -0.84px;
      }

      p {
        margin: 1em 0;
        font-family: $fontGotham;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.65px;
        color: #a09e98;
      }

      h1 {
        display: block;
        width: 100%;
        margin: 0 85px 8px 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.16px;
        font-size: 2.75rem;
        font-weight: bold;
        color: #003e69;


        &:after {
          content: '';
          display: block;
          width: 2.5rem;
          height: 0.25rem;
          background-color: #5cb7e7;
          margin-top: 8px;
        }
      }

      h2 {
        display: block;
        width: 100%;
        margin: 0 85px 8px 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.16px;
        font-size: 2rem;
        font-weight: bold;
        color: #003e69;

      }

      h3 {
        display: block;
        width: 100%;
        margin: 0 85px 8px 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.16px;
        font-size: 2rem;
        font-weight: bold;
        color: #323232;
      }

      h4 {
        display: block;
        width: 100%;
        margin: 0 85px 8px 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.16px;
        font-size: 1.75rem;
        font-weight: bold;
        color: #003e69;
      }

      h5 {
        display: block;
        width: 100%;
        margin: 0 85px 8px 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.16px;
        font-size: 1.75rem;
        font-weight: bold;
        color: #323232;
      }

      h6 {
        display: block;
        width: 100%;
        margin: 0 85px 8px 0;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.16px;
        font-size: 1.50rem;
        font-weight: bold;
        color: #323232;
      }

      //p {
      //  margin-top: 1.25rem;
      //  margin-bottom: 1.25rem;
      //
      //}
      a {
        color: $primary;
        text-decoration: underline;
      }
    }
  }

  .testimonials {
    .testimonials-heading {
      margin: 0 0 30px;
      font-family: $fontGotham;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.84px;
      color: #5e5c58;
    }

    .testimonials-quote {
      position: relative;
      width: 100%;
      max-width: 575px;
      margin-bottom: 60px;
      padding: 8px 25px 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .quote-signs {
      position: absolute;

      &.left {
        top:0;
        left: 0;
      }

      &.right {
        bottom: 0;
        right: 0;
      }

      svg {
        width: 25px;

        path {
          fill: #0065c3;
        }
      }
    }

    .quote-text {
      margin: 0;
      font-family: $fontGotham;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -1.12px;
      text-align: center;
      color: #0065c3;
    }

    .quote-source {
      margin: 0;
      font-family: $fontGotham;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.56px;
      text-align: center;
      color: #0065c3;
    }
  }
}
