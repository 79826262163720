// Base

.content-blocks {
  display: flex;
  font-size: 1.6rem;

  //max-width: 68.75rem;
  margin-left: auto;
  margin-right: auto;
  //padding-left: 1rem;
  //padding-right: 1rem;
  position: relative;

  &.has-aside {
    max-width: 68.75rem;
  }

  @media(max-width: 768px) {
    flex-wrap: wrap;
  }

  p,a {
    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}

.aside-block {

  @media (max-width: 767px) {
    width: 100%;
  }
  .aside-related {
    display: block;
    margin-bottom: 2.5rem;
    width: 100%;

    @media (max-width: 767px) {
      width: 100%;
    }

    .related-event-wrapper {
      display: flex;
      flex-direction: column;

      .related-event {
        display: block;
        width: 100%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        margin-bottom: 20px;
        padding: 15px 18px;

        .related-event-title {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.84px;
          color: #54524e;
          margin-bottom: 8px;
        }

        .related-event-text {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.56px;
          color: #54524e;
          margin-bottom: 26px;
        }

        .related-event-link {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          text-align: right;

          color: #003e69;
          cursor: pointer;
          font-size: 0.875rem;
          font-weight: 700;
          padding: 0;
          background-color: transparent;
          border: 0;

          svg {
            margin-left: 10px;
          }
          @media (min-width: 1025px) {
            &:hover {
              span {
                border-bottom: 1px solid #003e69;
              }
            }
          }

        }

      }
    }

    .related-title {
      color: #4a4845;
      display: inline-block;
      font-size: 1.5rem;

      letter-spacing: -0.16px;
      margin-bottom: 0;
      margin-top: 0;
      padding-bottom: 0.325rem;
      position: relative;
      font-weight: 400;
      padding: 0;
      margin-bottom: 20px;
    }

    .topics-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 767px) {
        justify-content: flex-start;
      }
      .topic-link {
        background-color: #fff;
        border: 1px solid #5cb7e7;
        border-radius: 1.25rem;
        color: #003e69;
        display: block;
        font-size: 0.75rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        margin-top: 0.625rem;
        margin-left: 0.325rem;
        max-width: 160px;
        text-align: center;
        @media (min-width: 1025px) {
          &:hover {
            background-color: #5cb7e7;
            font-weight: 500;
          }
        }

      }
    }


  }
}

.blocks-wrapper {
  width: 100%;
}

.block-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 68.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  position: relative;


  &.is-scrollable {
    overflow: scroll;
  }

  @media(max-width: 768px) {
    max-width: 100%;
  }
}

.navigation {
  &.entry {
    .row  {
      flex-wrap: nowrap;
    }
  }
}

.block-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.is-hide {
    display: none;
  }

  &.is-visible {
    width: 100%;
    max-width: 30%;

    @media(max-width: 767px) {
      max-width: 100%;
    }
  }

  &.direction-column {
    flex-wrap: inherit;
    flex-direction: column;
  }

  &.direction-column-reverse {
    flex-wrap: inherit;
    flex-direction: column-reverse;
  }

  &.direction-row-reverse {
    flex-wrap: inherit;
    flex-direction: row-reverse;
  }
}

// Module Training
.module-training-methods {
  display: flex;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  width: 100%;

  @media (max-width: 767px) {
    overflow: scroll;
  }

  .training-method {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% / 5 - 12px);
    margin-right: 15px;

    border: 1px solid currentColor;
    border-bottom: 5px solid currentColor;

    transition: transform 0.25s ease-in-out;

    @media (max-width: 767px) {
      min-width: 188px;
      background-color: currentColor;
    }

    @media (min-width: 1025px) {
      &:hover {
        transform: scale(1.1);
        background-color: currentColor;

        .training-heading {
          color: #fff;
        }
      }
    }


    &:last-child {
      margin-right: 0;
    }

    .training-asset {
      display: block;
      width: 100%;
      padding: 1rem 2.75rem;
      padding-bottom: 0;
    }

    .training-heading {
      display: block;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 1.07px;
      text-align: center;
      color: #003e69;
      text-transform: uppercase;
      padding: 1rem 2.75rem;
      padding-top: 1rem;

      @media (max-width: 767px) {
        color: #fff;
      }
    }
  }

}

// Module Card -- Toggle Panel Version
.module-card {
  display: block;
  width: 100%;
  max-width: calc((100% / 2) - 15px);
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  .card-thumb {
    display: block;
    min-width: 100%;
    min-height: 300px;
  }

  .card-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    background-color: #fff;
    padding-top: 0.125rem;
    padding: 1.375rem 1.875rem;
    width: 100%;
    text-align: right;

    color: #003e69;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 700;

    span {
      margin-right: 10px;
    }
  }

  .card-list-wrapper {
    background-color: #fff;
    padding: 1.375rem 1.875rem;
    padding-bottom: 0.125rem;

    .list-heading {
      font-size: 1.125rem;
      font-weight: 500;

      color: #4a4845;

      letter-spacing: -0.16px;
      margin-bottom: 0;
      margin-top: 0;
      padding-bottom: 0.325rem;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 2.5rem;
        height: 0.25rem;
        background-color: #5cb7e7;
        margin-top: 8px;
        margin-bottom: 31px;
      }
    }

    .list-links-wrapper {
      margin-top: 1.5rem;

      .list-link {
        color: #003e69;
        font-size: 0.875rem;

        display: flex;


        justify-content: space-between;

        align-items: center;
        padding: 0.625rem 0;

        cursor: pointer;

        font-weight: 700;

        background-color: transparent;
        border: 0;
        border-top: 1px solid #003e69;

        &:last-child {
          border-bottom: 1px solid #003e69;
        }
      }
    }
  }
}

// Module Fact
.module-fact {
  width: 100%;
  padding-top: 31px;
  padding-bottom: 31px;

  .fact-grid-title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    color: #4a4845;
    margin-bottom: 31px;

    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      margin-top: 8px;
    }
  }

  .module-fact-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1094px) {
      overflow-x: scroll;
    }

    .fact-content {
      display: block;
      width: calc((100% / 4) - (45px / 4));
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border: solid 1px #e4e4e3;
      padding: 6px 12px;
      margin-right: 15px;
      min-width: 255px;
      min-height: 255px;

      &:last-child {
        margin-right: 0;
      }

      .fact-heading {
        font-size: 40px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -2.33px;
        color: #558f26;
        margin-bottom: 4px;

        @media (max-width: 1023px) {
          font-size: 30px;
        }

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      .fact-text {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.75px;
        color: #4a4845;
        margin-bottom: 22px;
      }

      img {
        display: block;
        width: 100%;
        max-width: 74px;
      }
    }
  }

  .module-fact-grid-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    margin-top: 2.5rem;
    width: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    color: #003e69;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 700;

    span {
      margin-right: 10px;
    }
  }
}


// Module Fact Figures
.component-factFigures {
  width: 100%;
}

.module-fact-figures {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  .row-2 {
    display: flex;
    width: 100%;

    @media (max-width: 769px) {
      flex-wrap: wrap;
    }

    .quote-fact {
      width: 50%;

      @media (max-width: 769px) {
        width: 100%;
        margin-right: 0;
      }
    }

    .col-2 {
      display: flex;
      flex-wrap: wrap;
      width: 50%;

      @media (max-width: 769px) {
        width: 100%;
        flex-wrap: nowrap;
      }

      @media (max-width: 767px) {
        display: block;
        height: 100%;
      }

      .fact-content {
        width: calc((100% / 2) - 8px);

        max-height: 221px;

        @media (max-width: 767px) {
          width: 100%;
        }

        &.second-child {
          margin-right: 0;
        }
      }

      .text-fact-first {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-height: 229.13px;
        height: 100%;

        font-size: 50px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -2.33px;
        text-align: center;
        color: #ffffff;

        margin-right: 0;

        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px #e4e4e3;
        background-image: linear-gradient(to bottom, #0065c3, #0065c3);

        @media (max-width: 1024px) {
          margin-left: 0 !important;
        }

        @media (max-width: 767px) {
          min-height: 229.13px;
          margin-left: 0;
          margin-bottom: 15px;
          font-size: 30px;
        }



        .text-fact-content {
          display: block;
          color: #fff;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .fact-content {
    display: block;
    width: calc((100% / 4) - (45px / 4));
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba($white, 20%);
    padding: 6px 12px;
    margin-right: 15px;
    margin-bottom: 15px;

    border: solid 1px #e4e4e3;
    padding: 6px 12px;
    margin-right: 15px;
    min-height: 255px;

    &:nth-child(4n+4) {
      margin-right: 0;
    }

    @media (max-width: 1024px) {
      width: calc((100% / 2) - (30px / 4));
      margin-bottom: 15px;

      &:nth-child(2n+2) {
        margin-right: 0px;
      }

      &:last-child {
        margin-right: 0;
        margin-left: 15px;
      }

      &:nth-child(9) {
        margin-right: 0;
        margin-left: 15px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 15px;

      &:nth-child(2n+2) {
        margin-right: 0px;
      }

      &:last-child {
        margin-right: 0;
        margin-left: 0px;
      }

      &:nth-child(9) {
        margin-right: 0;
        margin-left: 0px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

    }

    &.text-fact-second {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc((100% / 2) - (45px / 4));
      min-height: 255px;
      //height: 100%;

      font-size: 50px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -2.33px;
      text-align: center;
      color: #ffffff;

      background-color: $primary;

      @media (max-width: 1024px) {
        margin-right: 0;
        margin-left: 15px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        font-size: 30px;
      }

      .text-fact-content {
        display: block;
        color: #fff;
        width: 100%;
        text-align: center;
      }
    }

    &.quote-fact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 42px 62px;
      //width: calc((100% / 2) - (45px / 4));
      background-color: #539535;

      min-height: 467.25px;
      //height: 100%;

      @media (max-width: 769px) {
        min-height: 267.25px;
      }

      .quote-content {
        display: block;
        width: 100%;

        font-size: 50px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -2.33px;
        text-align: center;
        color: #ffffff;

        @media (max-width: 768px) {
          font-size: 30px;
        }
      }

      .quote-source {
        display: block;
        width: 100%;

        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.65px;
        text-align: center;
        color: #ffffff;
        margin-top: 12px;
      }
    }

    .fact-heading {
      display: block;
      max-width: 232px;
      width: 100%;
      font-size: 40px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -2.33px;
      color: #4b842e;
      margin-bottom: 4px;

      @media (max-width: 1023px) {
        font-size: 30px;
      }

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    .fact-text {
      display: block;
      width: 100%;
      max-width: 232px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.75px;
      color: #4a4845;

      margin-bottom: 6px;
    }

    img {
      display: block;
      width: 100%;
      max-width: 74px;
    }
  }
}

// Components Heading
.component-heading {
  display: block;
  width: 100%;
  margin: 0 85px 8px 0;
  margin-bottom: 1rem;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.16px;
  color: #4a4845;


  &:after {
    content: '';
    display: block;
    width: 2.5rem;
    height: 0.25rem;
    background-color: #5cb7e7;
    margin-top: 8px;
  }
}

// Components Quote
.component-quote {
  display: block;
  width: 100%;
  margin: 0px 0px 4px 0;

  position: relative;
  //max-width: 705px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .quote-content {
    display: block;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -1.12px;
    color: #0065c3;
    text-align: center;
  }

  .quote-source {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.56px;
    text-align: center;
    color: #0065c3;
    margin-top: 12px;
  }

  .quote-signs {
    position: absolute;

    &.left {
      top:0;
      left: -25px;
    }

    &.right {
      bottom: 0;
      right: -25px;
    }

    svg {
      width: 25px;

      path {
        fill: #0065c3;
      }
    }
  }
}

// Components Rich Text
.component-rich-text {
  display: block;
  width: 100%;
  margin: 23px 0px 4px 0;
  font-size: 18px;
  letter-spacing: -0.56px;
  line-height: 1.75;
  color: #5e5c58;

  h1 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 2.75rem;
    font-weight: bold;
    color: #003e69;


    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      margin-top: 8px;
    }
  }

  h2 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 2rem;
    font-weight: bold;
    color: #003e69;

  }

  h3 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 2rem;
    font-weight: bold;
    color: #323232;
  }

  h4 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 1.75rem;
    font-weight: bold;
    color: #003e69;
  }

  h5 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 1.75rem;
    font-weight: bold;
    color: #323232;
  }

  h6 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 1.50rem;
    font-weight: bold;
    color: #323232;
  }

  p {
    //margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  a {
    color: $primary;
    text-decoration: underline;
  }
}

// Components Entry
.component-entry {
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  .entry-title  {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    color: #ffffff;

    margin-bottom: 31px;

    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      margin-top: 8px;
    }
  }

  .entry-wrapper {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-wrap: nowrap;
      overflow: scroll;
    }
  }

  .card-entry {
    display: block;
    width: calc((100% / 4) - (45px / 4));
    background-color: #fff;
    margin-right: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    &:last-child {
      margin-right: 0;
    }

    //@media (max-width: 1024px) {
    //  width: calc((100% / 2) - (30px / 4));
    //  margin-bottom: 15px;
    //
    //  &:nth-child(2n) {
    //    margin-right: 0;
    //  }
    //}

    @media (max-width: 924px) {
      width: 100%;
      margin-bottom: 0;
      min-width: 175px;

      &:nth-child(2n) {
        margin-right: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }

    .entry-embed-html {
      display: block;
      width: 100%;
      height: 100%;
      a {
        display: block;
        width: 100%;
        height: 100%;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .entry-thumb {
      display: block;
      width: 100%;
      height: 128px;
      background-color: #333;

      @media (max-width: 767px) {
        height: 88px;
      }
    }

    .entry-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 0.75rem;
      padding-top: 2rem;
      position: relative;

      .entry-class {
        padding: 9px 12px 9px 15px;
        background-color: #bd4f19;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 1.25px;
        color: #ffffff;
        position: absolute;
        left: 0;
        top: -15px;

        @media (max-width: 767px) {
          font-size: 8px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: 1px;
        }
      }

      .entry-heading {
        font-size: 1.125rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.16px;
        margin-bottom: 0;
        margin-top: 0;
        margin-bottom: 8px;
        color: #54524e;

        @media (max-width: 767px) {
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.56px;
        }
      }

      .entry-rich-text {
        font-size: 0.825rem;
        letter-spacing: -0.56px;
        line-height: 1.75;
        color: #54524e;
        margin-bottom: 26px;

        @media (max-width: 767px) {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.56px;
          margin-bottom: 10px;
          margin-top: 3px;
        }
      }

      .entry-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        text-align: right;

        color: #003e69;
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 700;
        padding: 0;
        background-color: transparent;
        border: 0;

        @media (max-width: 767px) {
          justify-content: flex-start;
          margin-top: 5px;

          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.08px;
        }

        svg {
          margin-left: 10px;
        }

      }
    }
  }

}

// Components asset
.component-asset {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.fullSize {
    max-width: 100%;
  }

  &.oneQuartersSize {
    max-width: 25%;
  }

  &.twoQuartersSize {
    max-width: 50%;
  }

  &.threeQuartersSize {
    max-width: 75%;
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;
    align-self: flex-start;

    &:first-child {
      margin-bottom: 20px;
    }

    &:nth-child(2) {
      width: 100%;
      max-width: 50%;
      margin-right: 16px;

      @media (max-width: 1023px) {
        max-width: 100%;

        margin-right: 0;

        margin-bottom: 16px;
      }
    }

    &:nth-child(3) {
      width: 100%;
      max-width: calc(50% - 16px);

      @media (max-width: 1023px) {
        max-width: 100%;
      }
    }
  }

  .video-container  {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-width: 425px;
    padding-top: 56.25%;

    &:first-child {
      max-width: 100%;
    }

    &:nth-child(2) {
      width: 100%;
      max-width: 50%;
      margin-right: 16px;

      @media (max-width: 1023px) {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    &:nth-child(3) {
      width: 100%;
      max-width: calc(50% - 16px);

      @media (max-width: 1023px) {
        max-width: 100%;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

// Component accordion
.module-accordion {
  display: block;
  margin-top: 1.875rem;
  width: 100%;

  .accordion-row {

    .accordion-heading {
      position: relative;
      display: block;
      width: 100%;
      font-size: 1.2rem;
      font-weight: 500;
      color: #003e69;
      padding: 1rem;
      cursor: pointer;
      padding-right: 65px;
    }


    &.typeA {
      border: 1px solid $blue;
      border-right: 5px solid $blue;
      margin-bottom: 0.75rem;
    }


    &.typeB {
      border-bottom: 1px solid #b8b8b8;
      border-right: none;
      border-left: none;
      border-top: none;
      margin-bottom: 0;

      &:first-child {
        border-top: 1px solid #b8b8b8;
      }
    }

    .component-asset {
      position: relative;
      display: block;
      width: 100%;
      font-size: 1.6rem;
      font-weight: 500;
      color: #003e69;
      padding: 1rem;
      cursor: pointer;
      padding-right: 65px;

      .plus-icon {
        position: absolute;
        right: 35px;
        display: inline-block;
        min-width: 20px;
        min-height: 20px;

        span {
          position: absolute;
          transition: .3s all ease;
          background: $colorAccent2;
          border-radius: 2px;

          &:first-child {
            top: 25%;
            bottom: 25%;
            width: 10%;
            left: 45%;
          }

          &:last-child {
            left: 25%;
            right: 25%;
            height: 10%;
            top: 45%;
          }
        }

        &.is-open {
          span {
            transform: rotate(90deg);

            &:last-child {
              left: 50%;
              right: 50%;
            }
          }
        }
      }
    }

    .accordion-text {
      display: none;
      opacity: 0;
      padding: 0px 1rem 1rem;
      color: #5e5c58;
      font-size: 1rem;

      &.is-active {
        display: block;
        opacity: 1;

        table {
          width: 100%;
          border: 1px solid $greyLighter;
          border-collapse: collapse;
          text-align: left;
          padding: 5px;
          font-family: $fontGotham;
          margin-bottom: 1.25rem;

          th, td {
            border: 1px solid $greyLighter;
            border-collapse: collapse;
            text-align: left;
            padding: 5px;
          }
        }

        h1 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 2.75rem;
          font-weight: bold;
          color: #003e69;


          &:after {
            content: '';
            display: block;
            width: 2.5rem;
            height: 0.25rem;
            background-color: #5cb7e7;
            margin-top: 8px;
          }
        }

        h2 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 2rem;
          font-weight: bold;
          color: #003e69;

        }

        h3 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 2rem;
          font-weight: bold;
          color: #323232;
        }

        h4 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 1.75rem;
          font-weight: bold;
          color: #003e69;
        }

        h5 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 1.75rem;
          font-weight: bold;
          color: #323232;
        }

        h6 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 1.50rem;
          font-weight: bold;
          color: #323232;
        }

        p {
          //margin-top: 1.25rem;
          margin-bottom: 1.25rem;
        }

        a {
          color: $primary;
          text-decoration: underline;
        }
      }
    }
  }
}

// Module Tabs
.module-tabs {
  display: block;
  margin-top: 1.875rem;
  width: 100%;

  .tabs-header-container {
    display: flex;
    min-width: 100%;

    .tab-header {
      background: transparent;
      border: 0;
      border-bottom: 5px solid #b8b8b8;
      color: #003e69;
      cursor: pointer;
      font-size: 0.75rem;
      font-weight: 500;

      letter-spacing: 1px;
      margin: 0;
      padding: 0.75rem;
      text-transform: uppercase;
      width: 100%;

      text-align: center;

      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }

      @media (min-width: 1025px) {
        &:hover {
          border-bottom: 5px solid #4a4845;
        }
      }


      &.is-active {
        border-bottom: 5px solid #4a4845;
      }
    }
  }

  .tabs-content-container {
    display: block;
    width: 100%;
    margin-top: 3.5rem;

    .tab-content {
      display: none;

      p {
        margin-bottom: 1rem;
      }

      &.is-active {
        display: block;
      }
    }
  }

  .tabs-wrapper {
    display: flex;
    flex-direction: column;
  }
}

//Component CTA
.component-cta {
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  .component-cta-wrapper {
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    padding-bottom: 2.75rem;
    padding-top: 2.75rem;

    .component-cta-content {
      max-width: 60%;

      @media (max-width: 767px) {
        max-width: 100%;
      }

      .component-cta-heading {
        display: block;
        width: 100%;
        margin: 0 85px 8px 0;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.16px;
        color: #fff;
        font-size: 2.75rem;
        font-weight: normal;

        @media (max-width: 767px) {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.12px;
        }
      }

      .component-cta-text {
        display: block;
        color: $primary;
        font-size: 1.5rem;
        margin-top: 24px;
        margin-bottom: 24px;

        @media (max-width: 767px) {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -0.56px;
        }

        p {
          margin: 0;
        }

        a {
          text-decoration: underline;
        }
      }

      .component-cta-button {
        display: flex;
        align-items: center;
        max-width: 228px;
        background-color: $primary;
        color: #fff;
        padding: 12px 8px;
        font-size: 24px;
        transition: all ease-out 0.2s;

        @media (max-width: 767px) {
          font-size: 18px;
        }

        svg {
          margin-left: 10px;
          max-width: 30px;

          &.light-arrow {
            display: block;
          }

          &.dark-arrow {
            display: none;
          }
        }

        @media (min-width: 1025px) {
          &:hover {
            background-color: $light;
            color: $primary;

            svg {
              &.light-arrow {
                display: none;
              }

              &.dark-arrow {
                display: block;
              }
            }
          }
        }



        &.lightBlue {
          background-color: $blue;
          color: $primary;

          svg {
            &.light-arrow {
              display: none;
            }

            &.dark-arrow {
              display: block;
            }
          }

          @media (min-width: 1025px) {
            &:hover {
              background-color: $primary;
              color: $light;

              svg {
                &.light-arrow {
                  display: block;
                }

                &.dark-arrow {
                  display: none;
                }
              }
            }
          }


        }

        &.white {
          color: $primary;
          background-color: $light;

          svg {
            &.light-arrow {
              display: none;
            }

            &.dark-arrow {
              display: block;
            }
          }

          @media (min-width: 1025px) {
            &:hover {
              background-color: $primary;
              color: $light;

              svg {
                &.light-arrow {
                  display: block;
                }

                &.dark-arrow {
                  display: none;
                }
              }
            }
          }


        }
      }
    }
  }
}

// twitter feed
.module-twitter {
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  .twitter-feed-title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    color: #5e5c58;

    margin-bottom: 31px;

    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      margin-top: 8px;
    }
  }

  .twitter-feed-btn {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 194px;
  }
  .twitter-feed {
    width: 100%;
    max-width: 1068px;

    .twit-wrapper {
      width: calc((100% / 3));
      border: 1px solid $blue;
      margin-bottom: 15px;
      background-color: #fff;
      align-self: baseline;

      &.not-display {
        display: none;
      }

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      @media(max-width: 1023px) {
        width: calc((100% / 2));

        &:nth-child(3n+3) {
          margin-right: 15px;
        }

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }

      @media(max-width: 699px) {
        width: 100%;
        margin-right: 0;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }

      .twit-content {
        padding: 10px;
      }

      .twit-header {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        svg {
          width: 100%;
          max-width: 26px;
        }

        .twit-header-account {
          display: flex;
          svg {
            width: 100%;
            max-width: 36px;
            margin-right: 5px;
          }

          .twit-account-identity {

            .twit-account-author {
              font-size: 16px;
              font-weight: bold;
              columns: $blue;
            }

            .twit-account {
              font-size: 12px;
              columns: $blue;
            }
          }
        }
      }

      .twit-texts {
        .twit-text {
          font-size: 14px;
          columns: $blue;
        }

        .twit-date {
          font-size: 12px;
          columns: $blue;
        }
      }

      .twit-socials {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .twit-likes {
          display: flex;
          align-items: center;
          font-size: 12px;
          columns: $blue;

          svg {
            width: 100%;
            max-width: 15px;
            margin-right: 5px;
          }
        }
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
}


// module community
.module-community-group {
  width: 100%;

  .community-group-wrapper {
    display: flex;
    flex-wrap: wrap;

    .community-group-card {
      width: 100%;
      max-width: calc((100% / 2) - 15px);

      margin-right: 15px;
      margin-bottom: 55px;

      box-shadow: 0 5px 10px 1px rgba($greyDark, 0.25%);

      &:nth-child(2n+2) {
        margin-right: 0;
      }

      @media (max-width: 764px) {
        max-width: 100%;
        margin-right: 0;
      }

      .card-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $greyDarker;
        height: 251px;

        border-bottom: 5px solid $blue;
        position: relative;

        img {
          display: block;
          width: 100%;
          max-width: 124px;
        }

        svg {
          display: block;
          position: absolute;
          right: 10px;
          bottom: 10px;
          width: 14px;
          height: 18px;
        }
      }

      .card-content-wrapper {
        padding: 30px 18px;
        padding-bottom: 10px;

        .card-title {
          font-size: 24px;
          line-height: 33px;
          letter-spacing: -0.16px;
          margin-bottom: 18px;
        }

        .card-text {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.65px;
        }

        .card-btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          text-align: right;


          span {
            font-size: 14px;
            line-height: 19px;
          }

          svg {
            margin-left: 10px;
          }

          @media (min-width: 1025px) {
            &:hover {
              span {
                text-decoration: underline;
              }
            }
          }


        }
      }
    }
  }
}

//module media
.module-medias {
  display: flex;
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  .media-video-wrapper {
    width: 100%;
    max-width: 50%;
    margin-right: 12px;

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 12px;
    }

    .media-video {
      width: 100%;
      box-shadow: 0 2px 4px rgba($dark, 0.20%);

      .video-image {
        width: 100%;
        height: 264px;
      }

      .video-content {
        padding: 7.25px 15px;
      }

      .video-type {
        margin: 0;
        font-size: 10px;
        letter-spacing: 1.25px;
        line-height: 1;
        text-transform: uppercase;
      }

      .video-title {
        font-size: 18px;
        letter-spacing: -0.84px;
        line-height: 1;
        color: $greyDark;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .video-text {
        font-size: 12px;
        letter-spacing: -0.56px;
        line-height: 1.2;
      }

      .video-link {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        text-align: right;


        span {
          font-size: 14px;
          line-height: 19px;
        }

        svg {
          margin-left: 10px;
        }

        @media (min-width: 1025px) {
          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }


      }
    }
  }

  .media-images-wrapper {
    display: flex;
    width: 100%;
    max-width: 50%;

    @media (max-width: 1024px) {
      max-width: 100%;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .image-wrapper {
      width: 100%;
      box-shadow: 0 2px 4px rgba($dark, 0.20%);

      &:first-child {
        margin-right: 12px;

        @media (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 12px;
        }
      }

      .image-content {
        padding: 7.25px 15px;
      }

      .image {
        width: 100%;
        height: 264px;
        background-color: $grey;
      }

      .image-title {
        font-size: 18px;
        letter-spacing: -0.84px;
        line-height: 1;
        color: $greyDark;
        margin-top: 5px;
      }

      .image-type {
        margin: 0;
        font-size: 10px;
        letter-spacing: 1.25px;
        line-height: 1;
        text-transform: uppercase;
      }

      .image-text {
        font-size: 12px;
        letter-spacing: -0.56px;
        line-height: 1.2;
      }

      .image-link {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        text-align: right;


        span {
          font-size: 14px;
          line-height: 19px;
        }

        svg {
          margin-left: 10px;
        }

        @media (min-width: 1025px) {
          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }


      }
    }
  }
}

// module list
.module-list {
  display: flex;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 30px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  &.regular-spacing {
    padding-top: 30px;
  }

  .large-spacing {
    padding-top: 60px;
  }

  .card-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: calc(100% / 2 - 7.5px);

    @media (max-width: 767px) {
      width: 100%;
    }

    &:first-child {
      margin-right: 15px;

      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
    .card-video-container  {
      position: relative;
      overflow: hidden;
      width: 100%;
      min-width: 100%;
      padding-top: 47.49%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    .card-asset {
      display: block;
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    .card-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      background-color: #fff;
      padding: 22px 30px;
      //height: 100%;
      //max-height: 327px;

      &.no-side-padding {
        padding: 0;
      }

      .card-title {
        margin: 0 56px 8px 1px;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.16px;
        color: #4a4845;

        &:after {
          content: '';
          display: block;
          width: 2.5rem;
          height: 0.25rem;
          background-color: #5cb7e7;
          margin-top: 8px;
        }
      }

      .card-link {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: right;
        color: #003e69;
        padding-top: 16px;

        span {
          margin-right: 10px;
        }

        svg {
          margin-right: 15px;
        }

        @media (min-width: 1025px) {
          &:hover {
            span {
              border-bottom: 1px solid #003e69;
            }
          }
        }


      }

      .card-topics {
        display: flex;
        flex-direction: column;

        .card-topic-link-wrapper[data-cards-accordion] {
          &.is-active {
            .card-topic-link {
              border-bottom: 0;

              svg {
                transform: rotate(90deg);
              }
            }

            .card-topic-content {
              max-height: 100%;
              padding: 16px 5px;
              border-bottom: 1px solid #b8b8b8;
            }
          }

          .card-topic-link {
            cursor: pointer;
          }

          .card-topic-content {
            position: relative;
            overflow: hidden;
            max-height: 0;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: -0.84px;
            color: #4a4845;

            a {
              color: #003e69;
              text-decoration: underline;
            }
          }
        }

        .card-topic-link {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 100%;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          color: #003e69;
          padding-top: 11px;
          padding-bottom: 9px;
          border-bottom: 1px solid #b8b8b8;
          transition: all .3s ease-in-out;

          svg {
            margin-right: 15px;
            min-width: 7px;
            transition: all .3s ease-in-out;
          }

          @media (min-width: 1025px) {
            &:hover {
              span {
                text-decoration: underline;
              }
            }
          }


        }
      }
    }
  }
}

// Module Training
.module-training {
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  .module-entry-related {
    width: 100%;
    max-width: 100%;
    margin-left: 0;

    .related-title {
      width: 100%;
      text-align: center;
    }
  }

  .module-training-title {
    display: block;
    width: 100%;
    margin-bottom: 31px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    color: #4a4845;

    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      margin-top: 8px;
    }
  }

  .module-training-text {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -.56px;
    color: #5e5c58;
    margin-bottom: 31px;
  }
}

// Module Introduction
.module-introduction {
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  &.no-spacing {
    .introduction-text {
      margin-bottom: 0;
    }
  }

  &.small-spacing {
    .introduction-text {
      margin-bottom: 25px;
    }
  }

  &.medium-spacing {
    .introduction-text {
      margin-bottom: 50px;
    }
  }

  &.large-spacing {
    .introduction-text {
      margin-bottom: 75px;
    }
  }

  .introduction-title {
    font-size: 45px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.3px;
    color: #003e69;
  }

  .introduction-text {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.84px;
    color: #4a4845;
    width: 100%;
    max-width: 705px;
    margin-bottom: 75px;

    h1 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 2.75rem;
      font-weight: bold;
      color: #003e69;


      &:after {
        content: '';
        display: block;
        width: 2.5rem;
        height: 0.25rem;
        background-color: #5cb7e7;
        margin-top: 8px;
      }
    }

    h2 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 2rem;
      font-weight: bold;
      color: #003e69;

    }

    h3 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 2rem;
      font-weight: bold;
      color: #323232;
    }

    h4 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 1.75rem;
      font-weight: bold;
      color: #003e69;
    }

    h5 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 1.75rem;
      font-weight: bold;
      color: #323232;
    }

    h6 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 1.50rem;
      font-weight: bold;
      color: #323232;
    }

    p {
      //margin-top: 1.25rem;
      margin-bottom: 1.25rem;

    }
    a {
      color: $primary;
      text-decoration: underline;
    }
  }
}


// Modules Popular Topics
.module-popular-topics {
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  .popular-topics-title {
    &.underlined {
      &:after {
        content: '';
        display: block;
        width: 2.5rem;
        height: 0.25rem;
        background-color: #5cb7e7;
        margin-top: 8px;
      }
    }

    &.big {
      margin-bottom: 25px;
      font-family: $fontGotham;
      font-size: 2.75rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      color: #003e69;

      &:after {
        content: none;
      }
    }
  }

  .popular-topics-text {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -.56px;
    color: #5e5c58;
  }

  .popular-topics-wrapper {
    display: flex;
    flex-wrap: wrap;

    &.is-full {
      flex-direction: column;

      .topics-wrapper {
        max-width: 100%;

        &:first-child {
          border-top: 1px solid #b8b8b8;
          margin-right: 0;
        }
      }
    }

    .topics-wrapper {
      display: block;
      width: 100%;
      max-width: calc(100% / 2 - 7.5px);

      &:first-child {
        margin-right: 15px;
      }

    }

    .card-link {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #003e69;
      padding-top: 11px;
      padding-bottom: 9px;
      border-bottom: 1px solid #b8b8b8;

      svg {
        margin-right: 15px;
      }

      @media (min-width: 1025px) {
        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }


    }
  }
}

// Module Experts Listing
.module-experts {
  display: flex;
  width: 100%;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .experts-col {
    &.filters {
      width: calc(100% / 3);

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &.content {
      width: 100%;

      &.third-width {
        width: calc((100% / 3) * 2);
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .filters-form {
    display: block;

    @media(max-width: 767px) {
      display: none;
    }
  }

  .filters-mobile-trigger {
    display: none;
    align-items: center;
    padding: 20px;
    border :1px solid $blue;
    cursor: pointer;

    p {
      margin: 10px 0 0;
      font-family: $fontGotham;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.75px;
      color: $grey;
    }

    svg {
      width: 22px;
      margin-left: 20px;

      path {
        fill: $grey;
      }
    }

    @media(max-width: 767px) {
      display: inline-flex;
    }
  }

  .sorts-mobile-trigger {
    display: none;
    align-items: center;
    padding: 15px;
    border: 1px solid $blue;
    cursor: pointer;

    p {
      margin: 10px 0 0;
      font-family: $fontGotham;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.75px;
      color: $grey;
    }

    svg {
      width: 18px;
      margin-left: 20px;

      path {
        fill: $grey;
      }
    }

    @media(max-width: 767px) {
      display: inline-flex;
    }
  }

  // Sorts
  .sort-group {
    display: flex;
    align-items: center;

    @media(max-width: 767px) {
      display: none;
    }

    label {
      margin-right: 10px;
      font-family: $fontGotham;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: $grey;
    }
  }

  .experts-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    .top-title {
      font-family: $fontGotham;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: $grey;
    }
  }

  .experts-cards {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    .experts-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: calc((100% / 3) - 10px);
      margin-right: 15px;
      margin-bottom: 70px;

      &:nth-of-type(3n + 3) {
        margin-right: 0;
      }

      @media (max-width: 767px) {
        width: calc(50% - 15px);

        &:nth-of-type(3n + 3) {
          margin-right: 15px;
        }

        &:nth-of-type(2n + 2) {
          margin-right: 0;
        }
      }

      .card-img {
        display: block;
        width: 100%;
        margin-bottom: 15px;
      }

      .card-name {
        margin: 0;
        font-family: $fontGotham;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $grey;
      }

      .card-certification {
        position: relative;
        margin: 0;
        font-family: $fontGotham;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: normal;
        color: $grey;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 20px;
          height: 2px;
          background: $blue;
        }
      }

      .card-summary {
        margin: 15px 0;
        font-family: $fontGotham;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: $grey;

        a {
          color: $primary;
          text-decoration: underline;
        }
      }

      .card-link {
        font-family: $fontGotham;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: right;
        color: $primary;
        transition: .3s all ease;

        @media (min-width: 1025px) {
          &:hover {
            color: $blue
          }
        }

      }
    }
  }
}

// Module Events
.module-events {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 31px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .events-title {
    display: block;
    width: 100%;
    max-width: 703px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -1.12px;
    color: #4a4845;
    margin-bottom: 23px;
  }

  .events-text {
    display: block;
    width: 100%;
    max-width: 703px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.65px;
    color: #4a4845;
  }

  .events-col {
    &.filters {
      width: calc(100% / 3);

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &.content {
      width: calc((100% / 3) * 2);

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .filters-form {
    display: block;

    @media(max-width: 767px) {
      display: none;
    }
  }

  .filters-mobile-trigger {
    display: none;
    align-items: center;
    padding: 20px;
    border: 1px solid $blue;
    cursor: pointer;

    p {
      margin: 10px 0 0;
      font-family: $fontGotham;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.75px;
      color: $grey;
    }

    svg {
      width: 22px;
      margin-left: 20px;

      path {
        fill: $grey;
      }
    }

    @media(max-width: 767px) {
      display: inline-flex;
    }
  }

  .sorts-mobile-trigger {
    display: none;
    align-items: center;
    padding: 15px;
    border: 1px solid $blue;
    cursor: pointer;

    p {
      margin: 10px 0 0;
      font-family: $fontGotham;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.75px;
      color: $grey;
    }

    svg {
      width: 18px;
      margin-left: 20px;

      path {
        fill: $grey;
      }
    }

    @media(max-width: 767px) {
      display: inline-flex;
    }
  }

  // Sorts
  .sort-group {
    display: flex;
    align-items: center;

    @media(max-width: 767px) {
      display: none;
    }

    label {
      margin-right: 10px;
      font-family: $fontGotham;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #4a4845;
    }
  }

  .events-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    .top-title {
      font-family: $fontGotham;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #696763;
    }
  }

  .events-cards {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .events-month {
      margin: 35px 0 25px;
      font-family: $fontGotham;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: $grey;
    }

    .events-card {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      padding: 15px;
      margin-bottom: 15px;
      border: 1px solid $blue;
      border-right: 5px solid $blue;

      &:last-of-type {
        margin-bottom: 0;
      }

      .card-left {
        width: calc(100% - 140px);
      }

      .card-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width: 140px;
      }

      .card-date {
        display: block;
        margin-bottom: 4px;
        font-family: $fontGotham;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 125%;
        letter-spacing: 1.25px;
        color: $grey;
      }

      .card-name {
        display: block;
        margin-bottom: 5px;
        font-family: $fontGotham;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 125%;
        letter-spacing: normal;
        color: $grey;
      }

      .card-summary {
        display: block;
        margin: 0;
        font-family: $fontGotham;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 125%;
        letter-spacing: -0.56px;
        color: $grey;
      }

      .card-length {
        font-family: $fontGotham;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 125%;
        letter-spacing: 1.25px;
        text-align: right;
        color: $grey;
      }

      .card-link {
        font-family: $fontGotham;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 125%;
        letter-spacing: normal;
        text-align: right;
        color: $primary;
        transition: .3s all ease;

        @media (min-width: 1025px) {
          &:hover {
            color: $blue;
          }
        }

      }
    }
  }
}

// Module Legislation Listing
.module-legislation {
  width: 100%;

  .legislation-categories {
    display: flex;
    align-items: stretch;
    width: calc((100% / 3) * 2);
    margin-left: auto;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 30px;
    }

    .category {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: calc((100% / 3) - 10px);
      margin-right: 15px;
      padding: 15px;
      border-bottom: 5px solid #b8b8b8;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }

      &.is-active {
        border-color: #4a4845;
      }

      .category-img {
        display: block;
        width: 36px;
        margin-bottom: 10px;

        @media (max-width: 767px) {
          width: 20px;
        }
      }

      .category-title {
        margin: 0 auto;
        font-family: $fontGotham;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        text-transform: uppercase;
        letter-spacing: 1.07px;
        text-align: center;
        color: $colorAccent2;

        @media (max-width: 767px) {
          font-size: 9px;
          line-height: 1.56;
          letter-spacing: 0.8px;
        }
      }
    }
  }

  .legislation-container {
    display: flex;
    width: 100%;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  .legislation-col {
    &.filters {
      width: calc(100% / 3);

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &.content {
      width: calc((100% / 3) * 2);

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .legislation-title {
    margin: 0 0 24px;
    font-family: $fontGotham;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #4a4845;
  }

  .legislation-cards {
    width: 100%;
  }

  .legislation-card {
    overflow: hidden;
    width: 100%;
    margin-bottom: 15px;
    color: $white;
    border: 1px solid $colorAccent1;
    border-right: 5px solid $colorAccent1;

    &.is-archive {
      display: none;
    }

    &.is-archive.has-archive {
      display: block;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;

      &:checked {
        + .card-header {
          .header-status,
          .header-jurisdiction {
            opacity: 0;
          }

          .header-btn {
            span {
              transform: rotate(90deg);

              &:last-child {
                left: 50%;
                right: 50%;
              }
            }
          }
        }

        ~ .card-content {
          max-height: 100vh;
          padding: 0 15px 15px;
        }
      }
    }

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      font-family: $fontGotham;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: $colorAccent2;
      cursor: pointer;

      .header-right {
        display: flex;
        align-items: center;
        padding-left: 30px;
      }

      .header-jurisdiction {
        width: 20px;
        margin-right: 30px;
        opacity: 1;
        transition: .3s all ease;
      }

      .header-status {
        min-width: 80px;
        max-width: 80px;
        margin-right: 15px;
        font-family: $fontGotham;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 1.25px;
        text-align: right;
        text-transform: uppercase;
        color: #4a4845;
        opacity: 1;
        transition: .3s all ease;
      }

      .header-btn {
        position: relative;
        display: inline-block;
        min-width: 20px;
        min-height: 20px;

        span {
          position: absolute;
          transition: .3s all ease;
          background: $colorAccent2;
          border-radius: 2px;

          &:first-child {
            top: 25%;
            bottom: 25%;
            width: 10%;
            left: 45%;
          }

          &:last-child {
            left: 25%;
            right: 25%;
            height: 10%;
            top: 45%;
          }
        }
      }
    }

    .card-content {
      max-height: 0;
      padding: 0 15px 0;
      color: $colorAccent2;
      background: $white;
      transition: all .5s cubic-bezier(0.5, 1, 0.89, 1);

      table {
        width: 100%;
        margin-bottom: 25px;
        border-spacing: 0;
        border-top: solid 1px #b8b8b8;
        border-bottom: solid 1px #b8b8b8;
      }

      thead tr th {
        padding: 10px 15px 10px 0;
        border-bottom: solid 1px #b8b8b8;
        font-family: $fontGotham;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        text-transform: uppercase;
        text-align: left;
        letter-spacing: 1.25px;
        color: #4a4845;

        &:last-of-type {
          padding: 10px 0;
          //text-align: right;
        }
      }
    }

    tbody tr td {
      padding: 10px 15px 10px 0;
      font-family: $fontGotham;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #4a4845;

      &:last-of-type {
        padding: 10px 0;
        //text-align: right;
      }

      img {
        width: 20px;
        margin-right: 8px;
      }

      p {
        margin: 0;
      }
    }

    .cell-wrapper {
      display: flex;
      align-items: center;
    }

    .card-text {
      margin-bottom: 25px;
      font-family: $fontGotham;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #696763;

      b,
      strong {
        font-weight: 500;
      }

      p {
        margin: 1em 0;
      }

      //ol,
      //ul {
      //  padding-left: 15px;
      //}
    }

    .card-footer {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .footer-link {
        position: static;
        display: inline-flex;
        align-items: center;
        font-family: $fontGotham;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #144677;

        &.is-share {
          @media (min-width: 1025px) {
            &:hover {
              .share-toolbox {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        span {
          margin-right: 5px;
        }

        svg {
          width: 12px;

          path {
            fill: $colorAccent2;
          }
        }
      }

      .share-toolbox {
        position: absolute;
        top: -100px;
        right: 0;
        z-index: 2;
        width: 100%;
        max-width: 140px;
        padding: 10px;
        background: $white;
        box-shadow: 0 2px 8px 0 rgba($black, .5);
        opacity: 0;
        visibility: hidden;
        transition: all .4s ease;

        label {
          margin-bottom: 3px;
          font-family: $fontGotham;
          font-size: 9px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.42px;
          color: $colorAccent2;
        }

        input {
          position: absolute;
          top: -5000px;
          left: -5000px;
          opacity: 0;
        }

        a {
          font-family: $fontGotham;
          font-size: 9px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.42px;
          text-decoration: underline;
          color: #5e5c58;
        }

        p {
          display: none;
          margin-top: 3px;
          font-family: $fontGotham;
          font-size: 9px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.42px;
          color: $colorAccent2;

          &.is-active {
            display: block;
          }
        }
      }
    }
  }
}

// Module Split Media and Links
#splitMediaLinks {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  .is-asset-fullwidth {
    .splitMediaLinks-video-container {
      width: 100%;
      padding-bottom: 56%;
    }
    
    .splitMediaLinks-assets {
      width: 100%;
    }
  }

  .splitMediaLinks-video-container {
    position: relative;
    width: calc(50% - 15px);
    min-height: 250px;
    height: 100%;
    padding-bottom: 28%;
    margin-bottom: 20px;
    overflow: hidden;
    background: #000;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .splitMediaLinks-asset {
    display: block;
    width: calc(50% - 15px);
    height: 100%;
  }

  .splitMediaLinks-content {
    width: calc(50% - 15px);

    .splitMediaLinks-title {
      margin-bottom: 25px;
      font-family: $fontGotham;
      font-size: 2.75rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      color: #003e69;
    }

    .splitMediaLinks-links {
      display: flex;
      flex-direction: column;
    }

    .splitMediaLinks-link {
      display: block;
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #003e69;
      padding-top: 11px;
      padding-bottom: 9px;
      border-bottom: 1px solid #b8b8b8;

      &:first-of-type {
        border-top: 1px solid #b8b8b8;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .splitMediaLinks-video-container {
      width: 100%;
      padding-bottom: 56.25%;
      margin-bottom: 25px;
    }

    .splitMediaLinks-asset {
      width: 100%;
      height: 300px;
      margin-bottom: 25px;
    }

    .splitMediaLinks-content {
      width: 100%;
    }
  }
}


// Module Sponsor
.component-sponsors {
  .sponsor-title {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 2.75rem;
    font-weight: bold;
    color: #003e69;


    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      margin-top: 8px;
    }
  }

  .sponsor-content {
    display: block;
    width: 100%;
    margin: 23px 0px 4px 0;
    font-size: 18px;
    letter-spacing: -0.56px;
    line-height: 1.75;
    color: #5e5c58;

    h1 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 2.75rem;
      font-weight: bold;
      color: #003e69;


      &:after {
        content: '';
        display: block;
        width: 2.5rem;
        height: 0.25rem;
        background-color: #5cb7e7;
        margin-top: 8px;
      }
    }

    h2 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 2rem;
      font-weight: bold;
      color: #003e69;

    }

    h3 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 2rem;
      font-weight: bold;
      color: #323232;
    }

    h4 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 1.75rem;
      font-weight: bold;
      color: #003e69;
    }

    h5 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 1.75rem;
      font-weight: bold;
      color: #323232;
    }

    h6 {
      display: block;
      width: 100%;
      margin: 0 85px 8px 0;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.16px;
      font-size: 1.50rem;
      font-weight: bold;
      color: #323232;
    }

    p {
      //margin-top: 1.25rem;
      margin-bottom: 1.25rem;

    }
    a {
      color: $primary;
      text-decoration: underline;
    }
  }

  .sponsors-grid {
    display: flex;
    justify-content: flex-start;

    flex-wrap: wrap;
    padding-top: 30px;
  }

  .sponsor-images {
    display: block;
    width: 100%;
    max-width: calc(100% / 3 - 30px);

    margin-right: 15px;
    margin-bottom: 15px;

    &:nth-child(3n+3) {
      margin-right: 0;
    }

    @media (max-width: 1024px) {
      margin-right: 0;
      max-width: 100%;
    }

    img {
      display: block;
      width: 100%;
    }

    .image-placeholder {
      width: 100%;
      height: 100%;
      background-color: #e4e4e3;
    }
  }
}

// Module Banner
.module-banner {
  display: flex;
  width: 100%;
  padding: 50px 157px;

  @media (max-width:1024px) {
    flex-wrap: wrap;
    padding: 30px 104px;
  }

  @media (max-width:767px) {
    padding: 25px 16px;
  }

  @media (max-width:599px) {
    padding: 20px 16px;
  }

  .banner-content-left {
    margin-right: 105px;

    @media (max-width: 1024px) {
      margin-right: 0;
      margin-bottom: 45px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .banner-content-left,
  .banner-content-right{
    width: 100%;
    max-width: 50%;

    @media (max-width:1024px) {
      max-width: 100%;
    }
  }

  .banner-heading {
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    color: #ffffff;
    margin-bottom: 28px;
  }

  .banner-text {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.84px;
    color: #ffffff;
  }

  .banner-asset {
    display: block;
    width: 100%;
    max-width: 435px;

    @media (max-width:599px) {
      max-width: 100%;
    }
  }

  .banner-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;

    span {
      margin-right: 5px;
    }

    svg {
      display: block;
      width: 5px;
    }
  }
}

.module-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  .search-heading {
    display: block;
    width: 100%;
  }
  .search-form {
    margin-top: 0;
  }
}


// Slider
.module-slider {
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  .slider-wrapper {
    display: block;
    width: 100%;

    .slide-wrapper {
      display: block;
      width: 100%;

      img {
        display: block;
        width: 100%;
        max-width: 100%;
        margin-bottom: 11px;
      }

      .slide-heading {
        display: block;
        width: 100%;
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.84px;
        color: #4a4845;
        margin-bottom: 6px;
      }

      .slide-text {
        display: block;
        width: 100%;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.56px;
        color: #54524e;
        margin-bottom: 11px;
      }

      .slide-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: right;
        color: #003e69;

        span {
          margin-right: 5px;
        }
      }


    }
  }
}

// Slider Colors
.module-color-slider {
  width: 100%;
  padding-top: 61px;
  padding-bottom: 30px;

  .slider-colors-wrapper {
    display: block;
    width: 100%;

    .slide-wrapper {
      display: flex;
      width: 100%;

      padding: 50px 140px;

      @media (max-width:1024px) {
        flex-wrap: wrap;
        padding: 30px 104px;
      }

      @media (max-width:767px) {
        padding: 25px 16px;
        flex-wrap: wrap;
      }

      @media (max-width:599px) {
        padding: 20px 16px;
      }

      .slide-left {
        width: 100%;
        max-width: 70%;
        min-width: 60%;

        @media (max-width:767px) {
          max-width: 100%;
        }

        .slide-heading {
          display: block;
          width: 100%;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          color: #ffffff;
        }

        .slide-text {
          display: block;
          width: 100%;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.84px;
          color: #ffffff;
        }
      }

      .slide-right {
        width: 100%;
        max-width: 50%;

        @media (max-width:767px) {
          max-width: 100%;
        }

        img {
          display: block;
          width: 100%;
          max-width: 453px;
        }

        .slide-link {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          text-align: right;
          color: #ffffff;

          span {
            margin-right: 5px;
          }

          svg {
            display: block;
            width: 5px;
          }
        }
      }
    }
  }
}


.module-links-intro {
  display: flex;

  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  @media (max-width: 767px) {
    flex-wrap: wrap;

    .navigation-child-links {
      width: 100%;
      margin-right: 0;

      .child-link {
        max-width: 100%;
      }
    }
  }



  .module-introduction {
    padding: 0;

    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }
}

.module-links-carousel {
  display: flex;
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  .module-slider {
    padding: 0;
  }
}


.small-business-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 31px;
  padding-bottom: 30px;

  .small-business-card {
    display: flex;
    width: 100%;
    max-width: calc(50% - 30px);
    margin-right: 60px;
    margin-bottom: 74px;

    @media (max-width: 1024px) {
      margin-right: 30px;
      margin-bottom: 37px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 60px;
      flex-direction: column-reverse;
    }

    &:nth-child(2n+2) {
      margin-right: 0;
    }

    .card-content-wrapper {
      margin-right: 30px;
      min-width: calc(100% - 210px);

      .card-links-wrapper {
        display: flex;
        flex-direction: column;

        .card-link {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 100%;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          color: #003e69;
          padding-top: 11px;
          padding-bottom: 9px;
          border-bottom: 1px solid #b8b8b8;

          svg {
            margin-right: 15px;
          }
          @media (min-width: 1025px) {
            &:hover {
              span {
                text-decoration: underline;
              }
            }
          }

        }
      }
    }

    .card-image-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 180px;
      max-height: 180px;
      min-width: 180px;
      min-height: 180px;
      border-radius: 50%;

      @media (max-width: 1024px) {
        min-width: 60px;
        min-height: 60px;

        max-width: 60px;
        max-height: 60px;
      }

      @media (max-width: 767px) {

        max-width: 180px;
        max-height: 180px;
        min-width: 180px;
        min-height: 180px;

        margin-bottom: 20px;
      }
    }
  }
}

.component-hubspot-form {
  width: 100%;
}

.module-important-updates {
  display: flex;
  width: 100%;
  padding-top: 35px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .important-updates-title {
    display: block;
    width: 100%;
    max-width: 127px;
    color: #fff;
    margin-right: 143px;

    @media (max-width: 1024px) {
      margin-right: 71px;
    }

    @media (max-width: 767px) {
      margin-right: 35px;
    }

    @media (max-width: 599px) {
      margin-right: 15px;
    }
  }

  .important-updates-content {
    display: flex;

    width: 100%;
    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;

    @media(max-width: 767px) {
      flex-direction: column;
      overflow-x: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .update-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #fff;
      border-top: 6px solid #ffd100;
      padding: 26px 10px 10px 15px;
      margin-left: 15px;
      position: relative;

      @media (max-width: 767px) {
        margin-left: 0;
        margin-bottom: 15px;
      }

      &:first-child {
        margin-left: 0;
      }

      .item-cat {
        display: block;
        position: absolute;
        top: 6px;
        left: 15px;

        font-family: $fontGotham;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 1.25px;
        color: #003e69;
        text-transform: uppercase;
      }

      &.has-image {
        max-width: 435px;

        @media (max-width: 767px) {
          max-width: 100%;
        }
      }

      &.no-image {
        max-width: 255px;

        @media (max-width: 767px) {
          max-width: 100%;
        }
      }

      img {
        display: block;
        width: auto;
        height: auto;
        max-width: 150px;
        max-height: 75px;
        margin-right: 15px;

        @media (max-width: 1024px) {
          max-width: 70px;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      .update-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .update-title {
          display: block;
          width: 100%;
          font-family: $fontGotham;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.75px;
          color: #4a4845;
          margin-bottom: 5px;

          @media (max-width: 1024px) {
            font-size: 14px;
          }

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        .update-text {
          display: block;
          width: 100%;
          font-family: $fontGotham;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.56px;
          color: #54524e;
          margin-bottom: 28px;

          @media (max-width: 1024px) {
            font-size: 10px;
          }

          @media (max-width: 767px) {
            font-size: 12px;
          }
        }

        .item-button {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;

          font-family: $fontGotham;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          text-align: right;
          color: #003e69;

          @media (max-width: 1024px) {
            font-size: 10px;
          }

          @media (max-width: 767px) {
            font-size: 12px;
          }

          svg {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.share-module {
  position: relative;
  width: 100%;

  .share-link {
    display: block;
    align-items: center;
    font-family: $fontGotham;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #144677;

    &.is-share {
      @media (min-width: 1025px) {
        &:hover {
          .share-toolbox {
            opacity: 1;
            visibility: visible;
          }
        }
      }

    }

    span {
      margin-right: 5px;
    }

    svg {
      width: 12px;

      path {
        fill: $colorAccent2;
      }
    }
  }

  .share-toolbox {
    position: absolute;
    top: 50%;
    left: 75px;
    transform: translate(0, -50%);
    z-index: 2;
    width: 100%;
    max-width: 240px;
    padding: 10px;
    background: $white;
    box-shadow: 0 2px 8px 0 rgba($black, .5);
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;

    //@media (max-width: 1024px) {
    //  left: 0;
    //  top: -50px;
    //  transform: translate(0, 0);
    //}

    label {
      margin-bottom: 3px;
      font-family: $fontGotham;
      font-size: 9px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.42px;
      color: $colorAccent2;
    }

    input {
      position: absolute;
      top: -5000px;
      left: -5000px;
      opacity: 0;
    }

    a {
      font-family: $fontGotham;
      font-size: 9px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.42px;
      text-decoration: underline;
      color: #5e5c58;
    }

    p {
      display: none;
      margin-top: 3px;
      font-family: $fontGotham;
      font-size: 9px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.42px;
      color: $colorAccent2;

      &.is-active {
        display: block;
      }
    }
  }
}

.thank-text {
  display: block;
  width: 100%;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;

  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.84px;
  color: #4a4845;

  margin-bottom: 75px;

  figure {
    display: block;
    width: 100%;
    max-width: 765px;
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
    }
  }

  h1 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 2.75rem;
    font-weight: bold;
    color: #003e69;


    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      margin-top: 8px;
    }
  }

  h2 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 2rem;
    font-weight: bold;
    color: #003e69;

  }

  h3 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 2rem;
    font-weight: bold;
    color: #323232;
  }

  h4 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 1.75rem;
    font-weight: bold;
    color: #003e69;
  }

  h5 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 1.75rem;
    font-weight: bold;
    color: #323232;
  }

  h6 {
    display: block;
    width: 100%;
    margin: 0 85px 8px 0;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.16px;
    font-size: 1.50rem;
    font-weight: bold;
    color: #323232;
  }

  p {
    margin-bottom: 1.25rem;

  }
  a {
    color: $primary;
    text-decoration: underline;
  }
}

#career {
  padding-top: 31px;
  padding-bottom: 31px;
  
  .block-content {
    #iframeHeightDiv {
      width: 100%;
      height: 500px;
      margin-bottom: 50px;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.CoveoRecommendation {
  font-family: gotham ssm;
  width: 100%;
  margin: 0 !important;
}  
.CoveoRecommendation .coveo-recommendation-header {
  border-bottom: thin solid #bcc3ca; 
}

.CoveoRecommendation .coveo-recommendation-header .coveo-recommendation-title {
  font-size: 20px;
  color: #003e69;
  font-family: gotham ssm;
  line-height: 1.35;
}

.CoveoRecommendation a.CoveoResultLink {
  word-wrap: break-word;
  white-space: break-spaces;
}  

.CoveoRecommendation .coveo-result-list-container {
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  padding: 20px; 
}
  
.CoveoRecommendation .coveo-result-list-container .coveo-list-layout {
    max-width: 320px;
    border-top: none; 
}
  
.CoveoRecommendation .coveo-result-list-container .coveo-result-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column; 
}

.CoveoRecommendation .coveo-result-list-container .coveo-result-cell {
    width: 100%;
    text-align: center;
    height: 160px;
    position: relative;
    min-width: 280px;
}

.CoveoRecommendation .coveo-result-list-container .coveo-title {
    max-width: 80%;
    margin: auto;
}

.CoveoRecommendation .shopify-thumbnail-cell {
  position: relative; 
}
  
.CoveoRecommendation .shopify-thumbnail-cell img {
    width: 280px; 
    height: 160px;
    margin: auto;
}

@media (max-width: 1024px) {
  .CoveoRecommendation .shopify-thumbnail-cell {
    width: 100%;
    padding-bottom: 20px; 
  } 
}
  
.CoveoRecommendation .shopify-thumbnail-cell .CoveoFieldValue:first-child:nth-last-child(1) {
    width: 100%;
    height: auto;
}

.CoveoRecommendation .shopify-thumbnail-cell .CoveoFieldValue:first-child:nth-last-child(1).shopify-category-wrapper span,
.CoveoRecommendation .shopify-thumbnail-cell .CoveoFieldValue:first-child:nth-last-child(1) .shopify-category {
      top: 0;
      position: relative; 
}

.CoveoRecommendation .shopify-thumbnail-cell .shopify-category-wrapper span {
    position: absolute;
    top: -10px;
    left: 0;
    display: block;
}

.CoveoRecommendation .shopify-thumbnail-cell .shopify-category-wrapper span.shopify-category {
      padding: 9px 13px 9px 15px;
      background: #6b1f7c;
      width: -webkit-max-content;
      width: max-content;
      font-family: "Gotham SSm", Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #FFFFFF;
      z-index: 1; 
}
  
.CoveoRecommendation .shopify-thumbnail-cell .shopify-category-wrapper span.shopify-category.classroom {
        background: #bd4f19;
}

.CoveoRecommendation .shopify-thumbnail-cell .shopify-category-wrapper span.shopify-category.ecourse {
        background: #6b1f7c;
}

.CoveoRecommendation .shopify-thumbnail-cell .shopify-category-wrapper span.shopify-category.webinar {
        background: #539535;
}

.CoveoRecommendation .shopify-thumbnail-cell .shopify-category-wrapper span.shopify-category.self-paced, .CoveoRecommendation .shopify-thumbnail-cell .shopify-category-wrapper span.shopify-category.reference-product {
        background: #0065c3;
}
      
.CoveoRecommendation .shopify-thumbnail-cell .shopify-category-wrapper span.shopify-category.safety-connection {
        background: #00806F;
}