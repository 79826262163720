.livechat-holder {
  position: fixed;
  bottom: 20px;
  right: 30px;


  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    bottom: 20px;
    left: 17px;
    background: rgba(0,0,0,0.48);
    width: 25px;
    height: 14px;
    box-shadow: 0 2px 18px 18px rgba(0,0,0,0.48);
    transition: all 0.5s ease-in-out;
    border-radius: 50%;
  }

  &:hover {
    &:before {
      box-shadow: 0 2px 18px 18px rgba(0,0,0,0.65);
    }
  }

  .livechat-icon {
    position: relative;
    display: block;
    max-width: 60px;
    cursor: pointer;
    border-radius: 50%;
  }
}
