//Global Search design
.acsb-trigger.acsb-ready {
  z-index: 1;
}

.coveo-footer-logo {
  display: none !important;
}

.site-container {

  .coveo-search-input {

    .magic-box {
      border-radius: 0;
      border-color: $blue;
    }

    .magic-box-underlay {
      height: 60px;
    }

    .magic-box-clear {
      line-height: 60px;
    }

    .magic-box-input {
      position: relative;
      height: 58px;
      border-radius: 0;

      input {
        font-family: $fontGotham;
        font-size: 14px;
        color: $dark;

        &::placeholder {
          color: #003e69;
          border-bottom: none;
        }

        @media (max-width: 1023px) {
          padding-left: 0;
        }
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: calc(100% - 30px);
        height: 1px;
        bottom: 15px;
        left: 0;
        right: 0;
        margin: auto;
        background: $blue;
      }
    }
  }

  .coveo-dynamic-facet-breadcrumb-value,
  .coveo-tag {
    padding: 8px 15px;
    border-radius: 20px;
    border: solid 1px $blue;
    background-color: $light;
    color: $primary;
    transition: all .3s ease-in-out;
    text-decoration: none;

    &:hover {
      background-color: $blue2;
      font-weight: 500;
    }
  }

  .coveo-dynamic-facet-breadcrumb-value {
    background-color: $blue2;
    font-weight: 500;
  }

  .coveo-list-layout {
    padding: 20px 0 20px 0;
  }

  .CoveoSearchButton.search-submit {
      height: 60px;
  }
}

//Global Search bar
.coveo-search-bar-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 60px 66px;
  background: $primary;
  transform: translateY(0);
  transition: all .5s ease-in-out;
  z-index: -1;

  &.is-active {
    transform: translateY(100%);
    z-index: 2;
  }

  .coveo-search-bar-contents {
    max-width: 68.75rem;
    margin: auto;
  }

  .coveo-header-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 75px;
  }

  .coveo-search-heading {
    display: block;
    width: 100%;
    margin: 0;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.16px;
    color: $light;
    font-size: 2.75rem;
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 32px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.12px;
    }

    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 0.25rem;
      background-color: #5cb7e7;
      margin-top: 8px;
    }
  }

  .coveo-search-close {
    cursor: pointer;
    margin-left: auto;
    transition: opacity .3s ease-in-out;

    &:hover {
      opacity: .7;
    }
  }

  .coveo-sub-title {
    font-family: $fontGotham;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.12px;
    text-align: center;
    color: $light;
    margin: 0 0 40px;
  }

  .coveo-tags-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px auto 0;
    max-width: 800px;
  }


  .coveo-categories {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    &:last-child {
      margin-bottom: 0;
    }

    p, a {
      font-family: $fontGotham;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }

    .coveo-category-title {
      margin-right: 20px;
      color: $light;

      @media (max-width: 1024px) {
        margin: 0 0 20px;
      }
    }


    .coveo-tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 1024px) {
        justify-content: center;
      }

      .coveo-tag {
        margin:0 10px 10px 0;
      }
    }
  }

  .coveo-search-input {
    .magic-box {
      border: 0;
    }

    .magic-box-input {
      height: 60px;
    }
  }
}

//Mobile Search Bar
.mobile-menu.is-active {
  #mobile-header-search {
    display: flex !important;
  }
}
#hero-search {
 .CoveoSearchbox {
    @media (max-width: 480px) {
      max-width: 100%;
      margin-right: 0 !important;
    }
  }

  @media (max-width: 370px) {
    .coveo-search-section {
      display: flex;
      flex-wrap: wrap;

      .CoveoSearchbox {
        width: 100%;
        max-width: 100%;
      }

      .CoveoSearchButton {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

#mobile-header-search.CoveoSearchInterface {
  .CoveoSearchbox {
    @media (max-width: 480px) {
      max-width: 100%;
      margin-right: 0 !important;
    }
  }

}
#mobile-header-search {
  display:none !important;

  .magic-box {
    border: 0;
  }

  .CoveoSearchButton {
    height: 60px;
    border: 0;

    .coveo-search-button-svg {
      width: 0;
      height: 0;
    }
  }

  .coveo-search-input {
    .magic-box-input  {
      input {
        padding-left: 2.25rem;
      }

      &:after {
        left: 2.25rem;
      }
    }
  }
}

//Results Page
.coveo-search-wrapper {
  .coveo-search-input {
    @media(max-width: 1024px) {
      margin-right: 0 !important;
    }
  }

  .coveo-breadcrumb-clear-all:not(.js-clear-filters) {
    display: none;
  }

  .coveo-dynamic-facet-search {
    display: none;
  }

  .js-clear-filters {
    margin: 0 0 10px 0;
    padding: 0;
    text-align: center;
  }

  .coveo-did-you-mean-highlight,
  .coveo-did-you-mean-word-correction{
    color: $dark;
    font-weight: bold;
  }

  .coveo-did-you-mean-automatic-correct {
    font-size: 14px;
  }

  .search-submit {
    display: block;
    width: 100%;
    max-width: 196px;
    background-color: $blue;
    border: 2px solid $blue;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $dark;
    transition: all .35s ease-in-out;


    &.mobile-result-search {
      display: none;
    }

    @media (max-width: 1024px) {
      display: none;

      &.mobile-result-search {
        display: flex;
        width: 65px;
        align-items: center;
        justify-content: center;
      }

      .coveo-search-button {
        display: none;
      }

    }

    &:hover {
      color: $light;
    }
  }

  .coveo-main-section {
    margin-top: 50px;
  }

  .coveo-tab-section {
    display: flex;
    justify-content: flex-end;
    background: transparent;
    border-bottom: none;
    margin-bottom: 56px;
    max-width: calc(100% - 28.01019%);
    margin-left: auto;
    text-align: right;
    padding-right: 10px;
    min-height: 0;

    @media (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
    }

    .CoveoTab {
      height: auto;
      border-bottom: 5px solid $greyLighter;
      margin-right: 16px;
      max-width: calc((100% / 4) - (16px * 3));
      transition: border .3s ease-in-out;

      @media(max-width: 1024px) {
        max-width: 100%;
      }

      p {
        font-family: $fontGotham;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.07px;
        text-align: center;
        color: $dark;
        margin: 0 0 13px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.coveo-selected,
      &:hover,
      &:focus {
        border-bottom: solid $dark 5px;

        p {
          border: none;
        }
      }
    }
  }

  .coveo-facet-column {
    max-width: 250px;
    margin-right: 115px;
    padding-bottom: 30px;

    @media(max-width: 1024px) {
      padding-left: 20px;
      margin-right: 40px;
    }

    .coveo-dynamic-facet-header-title span,
    .coveo-facet-title {
        font-family: $fontGotham;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grey;
    }

    .coveo-facet-title {
      font-size: 24px;
      margin: 25px 0 10px;
    }

    .coveo-dynamic-facet-values {
      span {
        text-transform: capitalize;
        font-family: $fontGotham;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $dark;
        text-overflow: initial;
        white-space: normal;

        &.coveo-checkbox-span-label {
          margin-right: 5px;
        }

        &.coveo-checkbox-span-label-suffix {
          margin-left: auto;
        }
      }

      .coveo-checkbox-label {
        align-items: flex-start;

      }

      .coveo-checkbox:checked+button:before {
        color: $dark;
      }
    }
  }

  .coveo-results-column {

    .coveo-summary-section {
      span {
        font-family: $fontGotham;
        color: $dark;
      }
    }

    .coveo-results-header {
        padding-right: 0;
    }

    .coveo-results-header>:not(:first-child) {
      margin-left: auto;

      @media(max-width: 800px) {
        flex: 0;
      }

      @media (max-width: 480px) {
        flex: 0 1 auto;
      }
    }
  }

  .coveo-date {
    float: left;
    margin: 0;
    font-family: $fontGotham;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    color: $grey;
  }

  .uberflip-type {
    span {
      display: inline-block;
      margin-top: 15px;
      font-family: $fontGotham;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      letter-spacing: 1.25px;
      color: $grey;

      &.coveo-field-caption {
        margin: 0 5px;
      }
    }
  }

  a.coveo-title {
    margin: 0;
    font-family: $fontGotham;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $primary;
    text-overflow: revert;
    white-space: break-spaces;

    &:hover {
      color: $primary !important;
    }

    &:visited {
      color: $greyDark;
    }

    & >* {
      vertical-align: baseline !important;
    }
  }

  .coveo-summary-text {
    font-family: $fontGotham;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.56px;
    color: $grey;
  }

  .coveo-extra-infos {
    display: none;
  }

  .coveo-result-link,
  .coveo-result-link a {
    font-family: $fontGotham;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.56px;
    color: $primary;

    &:visited {
      color: $greyLight;
    }
  }

  .coveo-result-frame {
    display: flex;
  }

  .coveo-result-frame {

    @media (max-width: 1024px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      border-right: none;
    }
  }

  .coveo-shopify-frame {
    padding-top: 10px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }


    .shopify-thumbnail-cell {
      position: relative;

      .coveo-thumbnail {
        background-position: left;
        background-size: contain;
        width: 280px;
        height: 140px;
        background-repeat: no-repeat;
        margin-right: 50px;

        @media (max-width: 1024px) {
          background-position: top center;
          width: 100%;
          height: 180px;
          margin-right: 0;
        }
      }

      @media (max-width: 1024px) {
        width: 100%;
        padding-bottom: 20px;
      }

      .CoveoFieldValue:first-child:nth-last-child(1) {
        /* -or- li:only-child { */
        width: 100%;
        height: auto;

        &.shopify-category-wrapper span, .shopify-category {
          top: 0;
          position: relative;
        }
      }

      .shopify-category-wrapper {
        > span:not(:only-child):first-child {
          //display:none;
        }

        span {

          position: absolute;
          top: -10px;
          left: 0;
          display: block;

          //&:not(:first-of-type) {
          //  display: none;
          //}

          &.shopify-category {
            padding: 9px 13px 9px 15px;
            background: #6b1f7c;
            width: max-content;
            font-family: $fontGotham;
            font-size: 10px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            color: $light;
            z-index: 1;

            &.classroom {
              background: $orangeBrown;
            }

            &.ecourse {
              background: $purple;
            }

            &.webinar {
              background: $green;
            }

            &.self-paced,
            &.reference-product
            {
              background: $blueLight;
            }

            &.safety-connection {
              background: $teal;
            }
          }
        }
      }
    }

    .shopify-content-cell {
      width: 80%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    .shopify-price-cell {
      margin-left:auto;
      width: 20%;
      text-align: right;

      @media (max-width: 1024px) {
        width: 100%;
        margin: auto;
      }


      .coveo-result-row {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .shopify-price-content {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        @media (max-width: 1024px) {
          margin-top: 20px;
          align-items: flex-end;
          flex-direction: row;
          justify-content: space-between;
        }

        .shopify-info,
        .shopify-price {
          font-family: $fontGotham;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-transform: uppercase;
          text-align: right;

          &.call-price {
            text-transform: none;
          }
        }

        .shopify-info {
          font-size: 10px;
          letter-spacing: 1.25px;
          color: $grey;

          @media (max-width: 1024px) {
            font-size: 12px;
          }
        }

        .shopify-price {
          margin-top: 5px;
          font-size: 14px;
          color: $greyLight;

          span {
            font-size: 12px;
            text-transform: lowercase;
          }
        }
      }

      .coveo-result-link,
      .coveo-result-link a {
        font-weight: 500;
      }

      .coveo-result-link {
        margin-top: auto;
        text-transform: uppercase;
      }
    }
  }

  .coveo-article-frame {
    border-left: 5px solid $blue;

    @media (max-width: 1024px) {
      border: none;
    }

    .article-content-cell {
      display: flex;
      flex-direction: column;
      //max-width: calc(100% - 180px);
      padding-left: 50px;

      @media (max-width: 1024px) {
        max-width: 100%;
        padding-left: 0;
      }

      .coveo-link-row {
        margin-top: auto;
      }
    }

    .article-thumbnail {
      width: 100%;
      //height: 180px;

      @media (max-width: 1024px) {
        padding-bottom: 10px;
      }

      .coveo-thumbnail {
        background-position: left;
        background-size: contain;
        width: 280px;
        height: 140px;
        background-repeat: no-repeat;

        @media (max-width: 1024px) {
          background-position: top center;
          width: 100%;
          height: 180px;
        }
      }
    }
  }
}
