.hero-breadcrum {
  background-color: $primary;

  .hero-breadcrum-content {
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    .breadcrum-link {
      color: #fff;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 700;
      padding: 0;
      background-color: transparent;
      border: 0;
      line-height: 2.5;
      &:after {
        content: '>';
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }


      &:last-child {
        &:after {
          display: none;
          content: '>';
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }


}

.hero-panel {
  background-color: $primary;

  .hero-panel-wrapper {
    // margin-left: auto;
    // margin-right: auto;
    // padding-left: 1rem;
    // padding-right: 1rem;
    // position: relative;
    padding-bottom: 2.75rem;
    padding-top: 2.75rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;

    &.no-img-no-text {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .hero-panel-content-wrapper {
      max-width: 68.75rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      position: relative;
    }

    .hero-panel-content {
      max-width: 70%;

      @media (max-width: 1024px) {
        max-width: 80%;
      }

      @media (max-width: 1024px) {
        max-width: 100%;
      }

      .hero-panel-heading {
        display: block;
        width: 100%;
        margin: 0 85px 8px 0;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.16px;
        color: #fff;
        font-size: 2.75rem;
        font-weight: bold;

        @media (max-width: 767px) {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.12px;
        }

        &:after {
          content: '';
          display: block;
          width: 2.5rem;
          height: 0.25rem;
          background-color: #5cb7e7;
          margin-top: 8px;
          margin-bottom: 31px;
        }

        &.has-no-text {
          margin-bottom: 0;

          &:after {
            margin-bottom: 0;
          }
        }
      }

      ::placeholder{
        font-size: 0.8125rem;
      }

      .search-form {


        @media (max-width: 767px) {
          width: 100%;
          min-width: 100%;
          max-width: 100%;

          button {
            max-width: 122px;
            font-size: 11px;
          }

          .input-wrapper {
            input {
              font-size: 11px;
            }
          }
        }
      }

      .hero-panel-text {
        display: block;
        color: #fff;
        font-size: 1.5rem;
        margin-top: 24px;

        @media (max-width: 767px) {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -0.56px;
        }

        p {
          margin: 0;
        }

        h1 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 2.75rem;
          font-weight: bold;
          color: #003e69;


          &:after {
            content: '';
            display: block;
            width: 2.5rem;
            height: 0.25rem;
            background-color: #5cb7e7;
            margin-top: 8px;
          }
        }

        h2 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 2rem;
          font-weight: bold;
          color: #003e69;

        }

        h3 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 2rem;
          font-weight: bold;
          color: #323232;
        }

        h4 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 1.75rem;
          font-weight: bold;
          color: #003e69;
        }

        h5 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 1.75rem;
          font-weight: bold;
          color: #323232;
        }

        h6 {
          display: block;
          width: 100%;
          margin: 0 85px 8px 0;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.16px;
          font-size: 1.50rem;
          font-weight: bold;
          color: #323232;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }
}
