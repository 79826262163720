.entry-parent {
  display: flex;

  width: 100%;
  max-width: 68.75rem;
  margin: 0 auto;

  padding-top: 61px;
  padding-bottom: 30px;

  padding-left: 1rem;
  padding-right: 1rem;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}
