button, input, optgroup, select, textarea {
  font-family: $fontGotham;
}

.form-select {
  width: 100%;
  border-radius: 15px;
  padding: 20px 45px 20px 24px;
  font-weight: bold;
  border: none;
  appearance: none;
  outline: none;
  background-color: $grey;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCA0Ij48cGF0aCBkPSJNOC41IDEuMjVMNS4wMTggMi45OSAxLjUzNyAxLjI1IiBzdHJva2U9IiM3Mzg3OUEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+");
  background-size: 7px 7px;
  background-repeat: no-repeat;
  background-position: center right 24px;
}

//.input-wrapper {
//  width: 100%;
//  position: relative;
//
//  &:last-child {
//    border: 0;
//  }
//
//  input, textarea {
//    width: 100%;
//    background: $grey;
//    border-radius: 15px;
//    padding: 27px 24px 10px;
//    font-weight: 800;
//    color: $dark;
//    appearance: none;
//    border: 0;
//    outline: 0;
//    transition: all ease-out .2s;
//
//    &:not(:placeholder-shown), &:focus {
//      & + label {
//        top: 6px;
//        transform: translateY(0);
//      }
//    }
//  }
//
//  textarea + label {
//    top: 20px;
//    transform: translateY(0);
//  }
//
//  label {
//    position: absolute;
//    left: 20px;
//    top: 50%;
//    transform: translateY(-50%);
//    font-weight: bold;
//    font-size: 14px;
//    line-height: 170%;
//    color: #6E7191;
//    opacity: 1;
//    pointer-events: none;
//    transition: all ease-out .2s;
//  }
//}

.form-group {
  display: flex;
  width: 100%;

  .input-wrapper.is-half {
    width: calc(50% - 10px);

    &:first-child {
      margin-right: 20px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    .input-wrapper.is-half {
      width: 100%;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}

.search-form {
  display: flex;
  width: 100%;
  margin-top: 53px;

  .input-wrapper {
    width: 100%;

    input {
      display: block;
      width: 100%;
      min-height: 60px;
      padding: 16px;
      border: none;

      font-size: 14px;
      color: #0D313F;

      &::placeholder {
        color: #003e69;
        border-bottom: 1px solid #5cb7e7;
      }
    }
  }

  button,
  .search-submit {
    display: block;
    width: 100%;
    max-width: 196px;
    background-color: #5cb7e7;
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #003e69;
    transition: all .35s ease-in-out;

    @media (min-width: 1025px) {
      &:hover {
        background-color: #fff;
        border: 1px solid #5cb7e7;
      }
    }


    @media(max-width: 465px) {
      max-width: 146px;
      font-size: 14px;
    }
  }
}

.select-wrapper {
  position: relative;
  padding: 2px 10px 2px 10px;
  border: solid 1px #bcbbba;
  background: transparent;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: -1;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid $colorAccent2;
    transform: translateY(-50%);
  }

  select {
    position: relative;
    width: 100%;
    padding-right: 20px;
    color: $colorAccent2;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: $fontGotham;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
  }
}

.checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
    justify-content: space-between;
    flex-direction: row-reverse;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: $white;

      &:after {
        opacity: 1;
      }
    }

    &:checked ~ .label {
      font-weight: 500;
    }
  }

  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid $colorAccent2;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .label {
    margin-left: 10px;
    margin-top: 6px;
    font-family: $fontGotham;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $colorAccent2;

    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 10px;

    }
  }
}

.checkmark {
  position: relative;
  display: inline-block;
  //top: 0;
  //left: 0;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  border: solid 1px #b8b8b8;
  background-color: $white;

  &:after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: all .2s ease;
  }
}
