// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
  font-family: $fontGotham;
}

body {
  &.no-scroll {
    overflow: hidden;
  }
}

body,
html {
  width: 100%;
  height: 100%;
  //font-size: 62.5%;
  line-height: 1.5;
}

html {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: sans-serif;

}

body {
  font-family: Helvetica, Arial, sans-serif;
  //@extend .regularText;
  color: $dark;
}

.fonts-loaded body {
  font-family: $fontGotham;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Helvetica, Arial, sans-serif;
}

.fonts-loaded h1,
.fonts-loaded h2,
.fonts-loaded h3,
.fonts-loaded h4,
.fonts-loaded h5,
.fonts-loaded h6, {
  font-family: $fontGotham;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #C2C7D0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #C2C7D0;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #C2C7D0;
}
:-moz-placeholder { /* Firefox 18- */
  color: #C2C7D0;
}

.hide-for-screen {
  font-size: 0;
}

/* -- For screen readers */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/* -- A subtle focus style for keyboard-input elements */
.text-input:focus {
    outline: 1px solid #5db7a5; /* Adjust to suit your tastes */
}

/* -- No outline for non-keyboard-inputs elements */
button:focus,
select:focus {
    outline: none;
}

html.user-is-tabbing *:focus {
    outline: 2px solid var(--outlineColor) !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
}

/* -- Fontello */

.fonts-loaded [class^="icon-"]:before {
    font-family: "fontello";
}

.fonts-loaded [class*=" icon-"]:before {
    font-family: "fontello";
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.responsive-img {
  width: 100%;
  height: 100%;
}

.flickity-prev-next-button {
  background: none;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 0;
  background-color: $primary;
}

.flickity-prev-next-button svg {
  //display: none;
  fill: white;
  max-width: 15px;


}

.flickity-prev-next-button .flickity-button-icon {
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.flickity-prev-next-button.previous {
  left: -60px;
}

@media (max-width: 1024px) {
  .flickity-prev-next-button.previous {
    background-color: $colorAccent1;
    width: 32px;
    height: 32px;
    left: 15px;
  }
}

//.flickity-prev-next-button.previous:before {
//  content: "\E827";
//  position: relative;
//  top: -1px;
//  font-family: fontello;
//  font-size: 28px;
//  color: black;
//}

@media (max-width: 1024px) {
  .flickity-prev-next-button.previous:before {
    top: 0;
    font-size: 19px;
    color: #fff;
  }
}

.flickity-prev-next-button.next {
  right: -60px;
}

@media (max-width: 1024px) {
  .flickity-prev-next-button.next {
    background-color: $colorAccent1;
    width: 32px;
    height: 32px;
    right: 15px;
  }
}

//.flickity-prev-next-button.next:before {
//  content: "\E826";
//  position: relative;
//  top: -1px;
//  font-family: fontello;
//  font-size: 28px;
//  color: black;
//}

@media (max-width: 1024px) {
  .flickity-prev-next-button.next:before {
    top:0;
    font-size: 19px;
    color: #fff;
  }
}

::-moz-selection { background: $colorAccent1; color: #fff; }
::selection { background: $colorAccent1; color: #fff; }

[v-cloak] {
  display: none;
}

.site-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;

  &.homepage {
    .main {
      margin-top: 0;
    }
  }
}

.container {
  width: 100%;
  max-width: calc(1140px + (2 * 30px));
  padding: 0 30px;

  @media (max-width: 767px) {
    max-width: calc(1140px + (2 * 16px));
    padding: 0 16px;
  }
}

.not-desktop {
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
}

.site-opener {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99995;
  animation: opener-animation-close 0s forwards 3.4s;

  .layer-dark1, .layer-light, .layer-dark2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $colorAccent2;
    will-change: transform;
    animation: opener-animation .2s forwards 3.25s;
  }

  .layer-light {
    background-color: $colorAccent1;
    animation: opener-animation .2s forwards 3.1s;
  }

  .layer-dark2 {
    animation: opener-animation .2s forwards 2.8s;
  }

  .svg-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes opener-animation {
    from {transform: translateY(0);}
    to {transform: translateY(100%);}
  }

  @keyframes opener-animation-close {
    from {pointer-events: auto;}
    to {pointer-events: none;}
  }
}

.effect-zoom-image {
  overflow: hidden;

  & > img {
    transition: transform ease-out 1s;
  }

  &:hover {
    & > img {
      transform: scale(1.1);
    }
  }
}


.page-not-found {
  display: flex;

  justify-content: center;
  align-items: center;

  max-width: 68.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4.5rem;
  padding-top: 4.5em;
  position: relative;

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
}

.browser-not-support {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  max-width: 68.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4.5rem;
  padding-top: 4.5em;
  position: relative;

  h1 {
    display: block;
    width: 100%;
    font-size: 2em;
    margin: 0.67em 0;
    text-align: center;
  }

  .latest-browser-urls {
    display: block;
    p {
      font-size: 1.1rem;
      font-style: italic;
    }
    a {
      text-decoration: underline;
    }
  }
}

.pagination ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .page-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding: 10px 15px;
    border: 1px solid #979797;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover,
    &.is-active {
      background-color: $primary;
      color: #fff;
    }

    &.is-active {
      cursor: default;
      pointer-events: none;
    }

    &.is-arrow {
      margin-bottom: -5px;
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      svg {
        width: 15px;
      }
    }
  }
}
