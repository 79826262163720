.filters {
  .filters-wrapper {
    .input-content {
      display: flex;
      line-height: 1;
      margin-bottom: 0.625rem;
      margin-top: 0.625rem;

      input {

      }

      label {
        font-size: 0.75rem;
        font-weight: 500;
        margin-left: 10px;
      }
    }

    .filter-title {
      color: rgb(84, 82, 78);
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0px;
      margin-top: 0px;
      line-height: 1.5;
    }

    .methods-wrapper {
      margin-bottom: 2rem;
    }

    .topics-wrapper {

    }
  }
}


// Filters
.filters-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.filters-label {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: $fontGotham;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $grey;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    font-size: 14px;
  }
}

.filters-clear {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: $fontGotham;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 15px;
  letter-spacing: normal;
  color: $colorAccent2;

  @media (max-width: 767px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  span {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-bottom: 8px;
    border-radius: 50%;
    background: $colorAccent2;
    overflow: hidden;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-left: 10px;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 9px;
      top: 50%;
      left: 3px;
      margin-top: -1.5px;
      background: $white;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.sidepanel-filters {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: none;

  &.is-active {
    display: block;

    &:before {
      transform: scaleX(1);
      transition-delay: 0s;
    }

    .sidepanel-filters-container {
      transform: scaleX(1);
      transition-delay: .5s;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background: rgba($black, .5);
    transform-origin: left;
    transform: scaleX(0);
    transition: all .3s ease;
    transition-delay: 1s;
  }

  .sidepanel-filters-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    max-width: 80%;
    border-right: 5px solid $blue;
    background: $white;
    transform-origin: left;
    transform: scaleX(0);
    transition: all .3s ease;
    transition-delay: 1.5s;
  }

  .sidepanel-header {
    position: relative;
    height: 50px;
    padding: 20px 40px 20px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $fontGotham;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.07px;
    text-transform: uppercase;
    color: $grey;
    border-bottom: solid 0.5px $grey;
  }

  .sidepanel-close {
    position: absolute;
    top: 50%;
    right: 40px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
    display: inline-block;
    cursor: pointer;

    svg {
      width :100%;
      color: $primary;
    }
  }

  .sidepanel-body {
    position: relative;
  }

  .filters-form {
    position: relative;
    height: calc(100vh - 50px);
    padding: 30px 40px 30px 25px;
    overflow-y: auto;
  }
}
