.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 170%;
  text-align: center;
  transition: all ease-out 0.2s;
  cursor: pointer;
  border: 1px solid $blue;
  background-color: $blue;
  color: $primary;
  cursor: pointer;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.75rem 1.75rem;

  @media (max-width: 520px) {
    width: 100%;
  }

  @media (min-width: 1025px) {
    &:hover {
      background-color: white;
      border-color: $primary;
    }
  }



  &.is-white {
    color: $primary;
    background-color: #fff;
    border: 1px solid #003E69;
  }


}

.link-with-arrow {
  position: relative;
  display: flex;
  align-items: center;
  color: $colorAccent1;
  font-weight: 800;

  &:after {
    content: '';
    display: flex;
    margin-left: 8px;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiA3Ij48cGF0aCBkPSJNMTIuMTE5IDIuNTA0SDEuMTA5Yy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxaDExLjAxdjEuNzljMCAuNDUuNTQuNjcuODUuMzVsMi43OC0yLjc5Yy4xOS0uMi4xOS0uNTEgMC0uNzFsLTIuNzgtMi43OWMtLjMxLS4zMi0uODUtLjA5LS44NS4zNXYxLjh6IiBmaWxsPSIjMDBCQ0U3Ii8+PC9zdmc+");
    background-position: center left 0px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    transition: background-position ease-out .2s;
  }

  @media (min-width: 1025px) {
    &:hover {
      &:after {
        animation: arrow-right 1s ease-out infinite;
      }
    }
  }

  &.is-reversed {
    &:after {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      margin-right: 8px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiA3Ij48cGF0aCBkPSJNMTIuMTE5IDIuNTA0SDEuMTA5Yy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxaDExLjAxdjEuNzljMCAuNDUuNTQuNjcuODUuMzVsMi43OC0yLjc5Yy4xOS0uMi4xOS0uNTEgMC0uNzFsLTIuNzgtMi43OWMtLjMxLS4zMi0uODUtLjA5LS44NS4zNXYxLjh6IiBmaWxsPSIjMDBCQ0U3Ii8+PC9zdmc+");
      background-position: center left 0px;
      background-repeat: no-repeat;
      background-size: 100% auto;
      transform: rotate(180deg);
    }

    @media (min-width: 1025px) {
      &:hover {
        &:before {
          animation: arrow-left 1s ease-out infinite;
        }
      }
    }
  }

  @keyframes arrow-right {
    0% {background-position: center left 0px;}
    48% {background-position: center left 16px;}
    49% {opacity: 0;}
    50% {background-position: center left -16px;}
    51% {opacity: 1;}
    100% {background-position: center left 0px;}
  }

  @keyframes arrow-left {
    0% {background-position: center right 0px;}
    48% {background-position: center right -16px;}
    49% {opacity: 0;}
    50% {background-position: center right 16px;}
    51% {opacity: 1;}
    100% {background-position: center right 0px;}
  }
}
