
.login {
  .login-title {
    width: 100%;
  }

  .messageContainer {
    max-width: 68.75rem;
    margin: 30px auto;
  }

  .downsize {
    width: 80%;
    display: flex;
    padding: 0;
    flex-direction: column;
    @media (max-width: 767px) {
      width: 100%;
      padding: 0 1rem;
    }
    > div {
      margin-left: calc((100% - 345px)/2);
    }
  }

  .header {
    color: #003e69;
    font-size: 20px;
    font-weight: 800;
  }

  .message {
    justify-content: center;
    color: #003e69;
    margin-top: 15px;
    display: inline;
    text-decoration: underline;
    margin-left: calc((100% - 345px)/2);
  }

  .login-page {
    display: flex;
    width: 100%;
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .ohnohoney,
  .ohnohoney-field {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }
}
